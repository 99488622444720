import React from 'react';

export default class Msg extends React.Component {
	constructor(props) {
		super(props);
		this.close = this.close.bind(this);
		this.closeAfter = this.closeAfter.bind(this);
		this.state = {
			text: this.props.text,
			type: this.props.type // error | success
		}
	}
	
	componentDidMount() {
		this.closeAfter();
	}
	
	componentDidUpdate() {
		this.closeAfter();
	}
	
	closeAfter() {
		clearTimeout(this.timerID);
		if (this.props.text) {
			const time_limit = 5000 + (this.props.text.length * 75);
			console.log(this.state.text && this.props.text.length, time_limit);
			this.timerID = setTimeout(() => {
				this.close();
			}, time_limit);
		}
	}

	componentWillUnmount() {
		clearTimeout(this.timerID);
	}

	async playSound(type) {
		const playPromise = new Audio('./sounds/'+type+'.wav').play();
		if (playPromise !== undefined) {
			playPromise.then(()=>{}).catch((error)=>{});
		}
	}

	close () {
		this.props.setMsg && this.props.setMsg('');
		this.props.onClose && this.props.onClose();
	}

	render() {
		const text = this.props.text;
		const type = this.props.type || 'error';
		text && this.playSound(type);
		return text ? (
			<div className={'msg ' + type}>
				<span>
					{text}
				</span>
				<button className="close" type="button" onClick={this.close} title="Fechar"></button>
			</div>
		) : null;
	}
} 