import React from 'react';
import { Link } from "react-router-dom";
import { getUsers } from "../utils/service.js";
import { isLogged, getUserData } from "../utils/JWTAuth.js";
import { formatDate } from "../utils/convert.js";
import Msg from '../components/msg';

class Users extends React.Component {
	constructor(props) {
		super(props);

		this.showError = this.showError.bind(this);
		this.hideError = this.hideError.bind(this);		
		this.ListUsers = this.ListUsers.bind(this);		

		this.state = {
			users: []
		};

		this.userData = getUserData();

        this.props.setLoading(true); 
	}
	
	async componentDidMount() {
		document.title = "Canal de Denúncias Unionlab - Lista de usuários";
		if (isLogged()) {
			let result = await getUsers();
			if (result && result.success) {
				result.data = result.data.map((o) => {
					o.self = false;
					if (o.id === this.userData.id) {
						o.nome += " (você)";
						o.self = true;
					}
					return o;
				});
				this.setState({users: result.data });
			}
			if (!result || !result.success) {
				this.showError("Provavelmente você não tem permissão para acessar a lista de usuários.");
				console.log(result);
			}
		} else {
			this.showError("Faça login para acessar a lista de usuários cadastrados.");
		}
	    this.props.setLoading(false); 
	}

	showError(msg) {
		this.setState({msg: msg});
	}
	
	hideError(msg) {
		this.setState({msg: ''});
	}

	rowUser(o) {
		const user = o.data;
		const link = user.self ? '/meus-dados' : "/usuario/"+user.id;
		return (
			<tr title={"Ver o usuário " + user.nome}>
				<td className="created">
					<Link to={link}>
						{user.datacriacao}
					</Link>
				</td>
				<td className="nome">
					<Link to={link}>
						{user.nome}
					</Link>
				</td>
				<td className="papel">
					<Link to={link}>
						{user.papel}
					</Link>
				</td>
				<td className="email">
					<Link to={link}>
						{user.email}
					</Link>
				</td>
			</tr>
		);
	}

	titleUser(o) {
		return (
				<tr>
					<th className="created">
						Data de criação
					</th>
					<th className="nome">
						Nome
					</th>
					<th className="papel">
						Papel
					</th>
					<th className="email">
						E-mail
					</th>
				</tr>
		);
	}

	groupByCategory(category, array, changeItem) {
		if (!array.length) { return array; }

		const categories = array.reduce(function (result, item) {
			//faz as alterações no item
			item = changeItem(item);
			
		    // Busca no mapCategories, um agregador para a categoria
		    var cat = result.mapCategories[item[category]];

		    // Cria um, se não existir
		    if (!cat) {
		        cat = result.mapCategories[item[category]] = {
		            category: item[category],
		            items: []
		        };
		        result.array.push(cat);
		    }

		    cat.items.push(item);

		    return result;
		}, { array: [], mapCategories: {}})

		// retorna o array ordenado por categoria
		.array.sort(function(a,b) {
			let aa = a["items"][0]["datacriacao"];
			let bb = b["items"][0]["datacriacao"];			
		    return (aa<bb)-(aa>bb);
		});

		return categories;
	}

	ListUsers() {
		const users = this.groupByCategory('papel', this.state.users, (o) => {
			o.datacriacao = formatDate(o.created, 'short');
			return o;
		});
		console.log(users);

		return users.map(c => {
			return (
			<table key={c.category}>
				<caption>{c.category}</caption>
				<thead>
					<this.titleUser />
				</thead>
				<tbody>
					{c.items.map(o => (
						<this.rowUser data={o} key={o.id} />
						)
					)}
				</tbody>
			</table>
			)
		});
	}

	render() {
		const users = this.state.users;
		return (
			<main className="users">
				<h1>
					<span>Lista de usuários</span>
					{isLogged() && this.userData.papel !== "Autor" && (
					<Link to="novo-usuario">
						<button>
							Adicionar usuário
						</button>
					</Link>
					)}
				</h1>
				<section>
					{users.length > 0 && (
						<this.ListUsers />
					)}
					{ users.length === 0 && (
						<p>Nenhum item para visualizar.</p>
					)}
				</section>
				<Msg text={this.state.msg} onClose={this.hideError} />
			</main>
		);
	}
}

export default Users;