import React from "react";
import GlobalContext from '../globalContext.js';
import { formatDate } from '../utils/convert.js';
import { getUserData } from "../utils/JWTAuth.js";
import { addMessage, getMessages } from "../utils/service.js";

class DialogChat extends React.Component {

    constructor(props) {
        super(props);
        this.handleChangeText = this.handleChangeText.bind(this);
        this.sendChat = this.sendChat.bind(this);
        this.getChat = this.getChat.bind(this);
        this.scrollToLast = this.scrollToLast.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);

        this.state = {
            text: '',
            messages: [],
            loading: false,
        };

        this.idInterval = null;

        this.text = React.createRef();
        this.last = React.createRef();
        this.form = React.createRef();
    }

    static contextType = GlobalContext;

    async componentDidMount() {
        await this.getChat();
        this.state.messages.length && this.scrollToLast();
        this.idInterval = setInterval(this.getChat, 3000);
        setTimeout(() => this.text.current.focus(), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.idInterval);
    }

    handleChangeText = (event) => {
        if (event.key) {
            console.log(event.key)
        }
        this.setState({ 
            text: event.target.value,
        });
    }

    async getChat(scroll=false) {
        const result = await getMessages(this.props.auati.id);
        if (result.success) {
            this.setState({messages: result.data}, () => {
                if (scroll) {
                    this.scrollToLast();
                }
            });
        }
    }

    async sendChat(event) {
        if (event && event.preventDefault) {
            event.preventDefault();
        }
        if (this.state.text === '') {
            return;
        }
        this.setState({ loading: true });
        const data = getUserData();
        if (data.id) {
            const formData = new FormData();
            formData.append('id_user',  data.id);
            formData.append('id_auati',  this.props.auati.id);
            formData.append('text',  this.state.text || '');
            const result = await addMessage(formData);
            if (!result.success) {
                this.setState({ loading: false });
                return this.context.setMsg('Não foi possível enviar a mensagem.', 'error');
            }
        } else {
            this.context.openDialog('login');
        }
        setTimeout(() => {
            this.getChat(true);
        }, 100);
        this.setState({ 
            loading: false,
            text: ''
         });
    }



    scrollToLast() {
        const list = this.state.messages;
        if (!list.length) {
            return null;
        }
        this.last.current.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
        });
    }
    
    handleKeyDown(e) {
        if(e.key === 'Enter' && !e.shiftKey) {
            this.sendChat();
        }
    }


    render() {
        
        const auati = this.props.auati;
        const requester = auati.person_requester;
        const user = getUserData(); 
        const list = this.state.messages;

        return (
            <div className='chat'>
                <div className="messages">

                    {list.length > 4 && (
                    <button
                        type="button"
                        onClick={this.scrollToLast}
                        disabled={list.length === 0}
                        >
                        Rolar para baixo
                    </button>
                    )}
                    <samp>
                        <b>@{requester.nickname}</b> solicitou Auati em {formatDate(auati.date_created, "short")}
                    </samp>
                    {!list.length && (
                        <span>Carregando...</span>
                    )}
                    {list.map(o => (
                    <div key={o.id} className={'message '+(o.id_user === user.id ? 'right' : 'left')}>
                        <figure className="avatar">
                            <img src={auati.person_requester.avatar} alt="" />
                        </figure>
                        <p className="balloon">
                            <span>{o.text}</span>
                            <time dateTime={o.date_created}>{formatDate(o.date_created, "short")}</time>
                        </p>
                    </div>
                    ))}
                    <div ref={this.last}></div>
                </div>

                <form onSubmit={this.sendChat} ref={this.form}>

                    <div className='field justification'>
                        <textarea 
                            ref={this.text}
                            name="text"
                            id="text"
                            value={this.state.text} 
                            placeholder='Escreva sua mensagem'
                            onChange={this.handleChangeText}
                            onKeyDown={this.handleKeyDown}
                            required='required'
                        ></textarea>
                    </div>

                    <footer>
                        <div className="buttons">
                            <button type='submit' disabled={this.state.loading || this.state.text.length === 0}>
                                Enviar
                            </button>
                        </div>
                    </footer>
                </form>
            </div>
        );
    }

}

export default DialogChat;