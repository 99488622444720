import React from "react";
import GlobalContext from '../globalContext.js';
import { formatDate } from '../utils/convert.js';
import { getUserData } from "../utils/JWTAuth.js";
import { setAuati } from "../utils/service.js";

class DialogResponseAuati extends React.Component {

    constructor(props) {
        super(props);
        this.setOption = this.setOption.bind(this);
        this.handleChangeText = this.handleChangeText.bind(this);
        this.sendResponse = this.sendResponse.bind(this);
        this.state = {
            text: '',
            optionSelected: null,
            canSend: false,
            loading: false
        };
        this.text = React.createRef();
    }

    static contextType = GlobalContext;

    componentDidMount() {
        this.setState({
            optionSelected: this.props.optionSelected
        });
    }

    handleChangeText = (event) => {
        this.setState({ 
            text: event.target.value,
            canSend: !this.state.loading && event.target.value.length > 1
        });
    }

    setOption(value) {
        this.props.setOption(value);
        this.setState({
            optionSelected: value,
        });
        setTimeout(()=>this.text.current.focus(), 300);
    }

    async sendResponse(event) {
        event.preventDefault();
        this.setState({ loading: true });
        const data = getUserData();
        if (data.id) {
            const formData = new FormData();
            formData.append('id_user',  data.id);
            formData.append('status',  this.state.optionSelected);
            formData.append('text',  this.state.text || '');
            const result = await setAuati(formData, this.props.auati.id);
            console.log(result);
            if (!result.success) {
                this.setState({ loading: false });
                return this.context.setMsg('Não foi possível responder à solicitação de Auati.', 'error');
            }
            this.context.setMsg('O Auati foi respondido.', 'success');
            this.props.loadAd();
        } else {
            this.context.openDialog('login');
        }
        this.context.closeDialog();
        this.setState({ loading: false });
    }

    render() {

        const ad = this.props.ad;
        const auati = this.props.auati;
        const options = this.props.options;
        const requester = auati.person_requester;
        const link_person = <a target="_blank" rel="noopener noreferrer" href={"https://auati.com.br/"+requester.nickname}>{requester.fullname}</a>;

        const openLink = () => window.location = requester.nickname;
        let placeholder = this.state.optionSelected === 'accepted' ? 'Escreva aqui sua proposta de troca' : 'Informe por que vai recusar a troca de serviços.'
        return (
            <div className='response'>
                <div className='message chat'>
                    <p>
                        Olá, {ad.person.fullname}!<br />
                        {link_person} viu este anúncio e gostaria de trocar serviços com você. <br />
                        Quem sabe vocês fazem um Auati?
                    </p>
                    {auati.messages.length && (
                    <div className="message">
                        <figure className="avatar">
                            <img src={auati.person_requester.avatar} alt="" />
                        </figure>
                        <p className="balloon">
                            <span>{auati.messages[0].text}</span>
                            <time dateTime={auati.messages[0].date_created}>{formatDate(auati.messages[0].date_created, "short")}</time>
                        </p>
                    </div>
                    )}

                    <button type="button" className='link' onClick={openLink}>Ver os anúncios de {requester.fullname}</button>
                </div>

                <form onSubmit={this.sendResponse}>

                    {!this.state.optionSelected && (
                        <p>
                            Selecione uma opção para responder a esta solicitação de Auati.
                        </p>
                    )}

                    <div className='buttongroup choice'>
                        {options.map(o => (
                            <button key={o.status}
                                type="button" 
                                className={o.class + (this.state.optionSelected === o.status ? ' selected' : '')} 
                                onClick={() => this.setOption(o.status)}
                            >{o.text}</button>
                        ))}
                    </div>
                    {this.state.optionSelected && (
                    <div className='field justification'>
                        {this.state.optionSelected === 'accepted' && (
                        <label>Comece a negociação com {requester.fullname}:</label>
                        )}
                        {this.state.optionSelected === 'rejected' && (
                        <label>Informe o motivo para {requester.fullname}:</label>
                        )}
                        <textarea 
                            ref={this.text}
                            name="text"
                            id="text"
                            value={this.state.text} 
                            placeholder={placeholder}
                            onChange={this.handleChangeText}
                            required='required'
                        ></textarea>
                    </div>
                    )}

                    <footer>
                        <div className="buttons">
                            <button type='submit' disabled={!this.state.canSend}>
                                Responder à solicitação
                            </button>
                        </div>
                    </footer>
                </form>
            </div>
        );
    }

}

export default DialogResponseAuati;