import React from 'react';

class About extends React.Component {
    render() {
        return  (
			<main className="about">
                <h1>Sobre o projeto Auati</h1>
				<section>
                    Texto.
                </section>
			</main>
		);
    }
}

export default About;