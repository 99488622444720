import React from 'react';
import GlobalContext from '../globalContext.js';
import { confirmEmail } from "../utils/JWTAuth.js";
import { withRouter } from 'react-router-dom'
import Msg from './msg';


class Confirm extends React.Component {

	constructor(props) {
		super(props);

		this.hideError = this.hideError.bind(this);

		const path = this.props.location.pathname;
		this.hash = path.split("/")[2];
        this.state = {
			situation: '',
			user: {}
		};
        this.props.setLoading(true);

	}
	
	static contextType = GlobalContext;
	
	async componentDidMount() {
        console.log(this.hash);
	    const result = await confirmEmail(this.hash);
        console.log(result);
	    const success = result && result.success;
		document.title = "Auati - " + (success ? "Cadastro ativado" : result.data);
		this.setState({
			situation: success ? 'success' : 'error',
			msg: success ? '' : result.data,
			user: success ? result.data[0] : {}
		});
		this.props.setLoading(false);
	}

	showError(msg) {
		this.setState({msg: msg});
	}
	
	hideError(msg) {
		this.setState({msg: ''});
	}

	render() {

		return (
			<main className="confirm">
				<section>
				{this.state.situation === 'success' && (<>
                    <h1>Olá, {this.state.user.person.nickname}</h1>
                    <p>
                        Sua conta está ativa!
                    </p>
                    <p>
                        Não se esqueça de completar seu cadastro para 
                        desfrutar de todos os recursos do Auati!
                    </p>
					<button type='button' onClick={() => this.context.openDialog('login')}>Fazer login</button>
                </>)}
                
                {this.state.situation === 'error' && (<>
                    <div>
                        <h3>
                            Não foi possível validar o e-mail.
                        </h3>
						<p>O link pode estar mal formatado ou seu e-mail já foi validado.</p>
                    </div>
                </>)}
				</section>
				<Msg text={this.state.msg} onClose={this.hideError} />
			</main>
		);
	}
}

export default withRouter(Confirm);