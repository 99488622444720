import React from 'react';
import { withRouter } from "react-router-dom";
import { isLogged, getUserData, setUserData } from "../utils/JWTAuth.js";
import Msg from "../components/msg.js";
import { setUser } from "../utils/service.js";


class Myaccount extends React.Component {
	constructor (props) {
		super(props);
		this.timerId = null;
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.showError = this.showError.bind(this);
		this.hideError = this.hideError.bind(this);
		this.handleError = this.handleError.bind(this);
		this.handleChangePassword = this.handleChangePassword.bind(this);

		const userData = getUserData();

		let data = userData;

		this.state = {
			data: data,
			msg: "",
			msgType: 'error',
			changePassword: false
		};

		if (!isLogged()) {
			this.props.history.push('/start');
		}
	    this.props.setLoading(true); 
	}

	async componentDidMount() {
		document.title = "Canal de Denúncias Unionlab - Meus dados de usuário";
		window.addEventListener('beforeunload', this.handleLeavePage);
		// load user data
		if (!isLogged()) {
			this.props.history.push('/');
		}				
		this.props.setLoading(false); 
	}

	componentWillUnmount() {
		window.removeEventListener('beforeunload', this.handleLeavePage);
	}

	handleLeavePage(e) {
		const confirmationMessage = 'Verifique se os dados foram salvos.';
		e.returnValue = confirmationMessage;     // Gecko, Trident, Chrome 34+
		return confirmationMessage;              // Gecko, WebKit, Chrome <34
	}

	handleError(el, msg = 'Erro desconhecido') {
		el.classList.add('error');
		return this.showError(msg);
	}

	async handleSubmit(event) {
		this.props.setLoading(true);

		event.preventDefault();
		const form = event.target;
		const data = this.state.data;

		//compare password1 and password2
		if (this.state.changePassword && data.senha !== data.senha2) {
			return this.handleError(form['senha2'], "A senha e a confirmação de senha devem ser iguais.") && false;
		}
		
		const formData = new FormData(form);
		const id = data.id;
		formData.append("id", id);
		const result = await setUser(formData, id);
		if (!result.success) {
			return this.showError(result.data || 'Não foi possível salvar seus dados.');
		}
		setUserData(result.data);
		this.setState({
			changePassword: false
		})
		this.props.setLoading(false);
		return this.showSuccess('Seus dados foram salvos');
	}

	handleInputChange(event) {
		const el = event.target;
		const name = el.name;
		const value = el.type === 'checkbox' ? (el.checked?1:0) : el.value;
		let data = this.state.data;
		data[name] = value;
		this.setState({ data: data });
		return el;
	}
	
	handleChangePassword(event) {
		const el = event.target;
		const value = el.checked ? 1 : 0;
		this.setState({ changePassword: value });
	}

	showError(msg) {
		this.setState({msg: '', msgType: 'error'});
		setTimeout(()=>this.setState({msg: msg}), 1);
	}
	
	showSuccess(msg) {
		this.setState({msg: '', msgType: 'success'});
		setTimeout(()=>this.setState({msg: msg}), 1);
	}
	
	hideError(msg) {
		this.setState({msg: ''});
	}

	render() {
		
		return (
			<main className="myaccount">
				<h1>
					<span>Meus dados</span>
				</h1>
				<section className="formaccount">
				<form autoComplete="off" onSubmit={this.handleSubmit}>
					<fieldset>
						<legend>
							Dados da conta de usuário
						</legend>
		                <div className="fields">
							<div className='field input text nome'>
								<span>Seu nome completo</span>
								<input required name='nome' type="text" value={this.state.data.nome} onChange={this.handleInputChange} />
							</div>
							<div className='field input email email'>
								<span>Seu e-mail</span>
								<input required autoComplete="off" name='email' type="email" value={this.state.data.email} onChange={this.handleInputChange} />
							</div>
							<div className='field input text tel1'>
								<span>Telefone 1</span>
								<input required id='tel1' name='tel1' type="text" value={this.state.data.tel1} onChange={this.handleInputChange} />
							</div>
							<div className='field input text tel2'>
								<span>Telefone 2</span>
								<input id='tel2' name='tel2' type="text" value={this.state.data.tel2} onChange={this.handleInputChange} />
							</div>
							<div className='field select preferecontatopor'>
								<span>Prefere contato por</span>
								<select 
									required={this.state.data.papel !== 'Admin'} 
									name='preferecontatopor' 
									value={this.state.data.preferecontatopor} 
									onChange={this.handleInputChange} >
			                        <option value="">Selecione o canal...</option>
			                        <option value="Telefone">Telefone</option>
			                        <option value="E-mail">E-mail</option>
			                        <option value="Whatsapp">Whatsapp</option>
			                    </select>
							</div>
							<div className='field select horarioparacontato'>
								<span>Melhor horário para contato</span>
								<select 
									required={this.state.data.papel !== 'Admin'}
									name='horarioparacontato' 
									value={this.state.data.horarioparacontato} 
									onChange={this.handleInputChange} >
			                        <option value="">Selecione o horário...</option>
          							<option value="de manhã (entre 8 e 12 horas)">de manhã (entre 8 e 12 horas)</option>
          							<option value="de tarde (entre 13 e 17 horas)">de tarde (entre 13 e 17 horas)</option>
          							<option value="de noite (entre 18 e 22 horas)">de noite (entre 18 e 22 horas)</option>
          						</select>
							</div>
							<div className='field input checkbox changePassword'>
								<div>
									<input
									name="changePassword"
									id="changePassword"
									type="checkbox"
									checked={this.state.changePassword}
									onChange={this.handleChangePassword} />
									<label htmlFor="changePassword">Quero alterar minha senha</label>
								</div>
							</div>

						{!!this.state.changePassword && (
						<>	
							<div />
							<div className='field input password senha'>
								<span>Senha</span>
								<input required name='senha' type="password" 
								value={this.state.data.senha} 
								onChange={this.handleInputChange} 
								pattern="^.{6,}$"
								title="A senha deve ter no mínimo 6 caracteres" />
							</div>
							<div className='field input password senha2'>
								<span>Confirmar senha</span>
								<input required name='senha2' type="password" 
								value={this.state.data.senha2} 
								onChange={this.handleInputChange} 
								pattern={this.state.data.senha}
								title="A confirmação da senha deve ser igual a senha informada." />
							</div>
						</>)}
						</div>

					</fieldset>
					
					
					<div className="buttons">
						<button type="submit" disabled={this.props.loading}>
							<span>Alterar meus dados</span>
						</button>
						{this.state.msg && <Msg text={this.state.msg} type={this.state.msgType} onClose={this.hideError} />}
					</div>
					
				</form>
				</section>
			</main>
		);
	}
}

export default withRouter(Myaccount);