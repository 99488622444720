import React from 'react';
import { handleChange, InputCheck, InputFile, InputRadio, InputText, pickFields, Select, Textarea } from '../components/fields';
import { addAd } from '../utils/service';

class NewAd extends React.Component {

    constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
        this.fieldNames = ['title', 'moreinformation', 'id_category', 'remote', 'poster', 'want_categories', 'want_text', 'agree'];
        /*
        
        slug
        
        
        
        ?id_address
        
        [images]
        ?[links]
        ?tel 
        []
        []

        accept_others
        want_all_categories
        

        current_rating
        status
        date_posted
        id_plan
        poster_schema
        */
        this.state = {
            fields: pickFields(null, this.fieldNames)
        }
    }

    showError(msg) {
		this.setState({msg: '', msgType: 'error'});
		setTimeout(()=>this.setState({msg: msg}), 1);
	}
	showSuccess(msg) {
		this.setState({msg: '', msgType: 'success'});
		setTimeout(()=>this.setState({msg: msg}), 1);
	}
	hideError(msg) {
		this.setState({msg: ''});
	}



	handleChange(event) {
		return handleChange(event, this);
	}

	async handleSubmit(event) {
		event.preventDefault();
		this.context.setLoading(true);

		const id = this.id;
		const form = event.target;
		const formData = new FormData(form);
		
		try {			
			const result = await addAd(formData, id, 'action');
			if (!result.success) {
				return this.showError(result.data || 'Não foi possível salvar as informações.');
			}
		} catch (error) {
			console.error("Erro\n", error);
			return;
		}

		localStorage.removeItem(this.identifier);
		
		this.setState({
			form: false,
			showConfirm: true
		});

		await this.context.loadPec();
		this.context.setLoading(false);
		// return this.showSuccess('Seus dados foram salvos');
	}

    render() {
        return (
            <main className='newad'>
                <section>
                    <h1>Novo anúncio</h1>
					<form onSubmit={this.handleSubmit} encType="multipart/form-data" method="post">
                        <div className='fields'>

                            <InputText
                                label='Título do anúncio'
                                name="title"
                                placeholder="Ex.: Limpeza de estofados"
                                value={ this.state.fields.title }
                                changeValue={ this.handleChange }
                                required={true}
                                instructions={ "Escolha um título curto e atraente sobre o que vocẽ faz." } 
                                title={ "Escolha um título curto e atraente sobre o que vocẽ faz." } 
                                pattern='.{3,64}'
                            />

                            <Textarea
                                label='Título do anúncio'
                                name="moreinformation"
                                placeholder="Exemplo: Ofereço limpeza de estofados à seco, impermeabilização de sofás, cadeiras, colchões, tapetes, bancos de veículos e outros estofados à domicílio."
                                value={ this.state.fields.moreinformation }
                                changeValue={ this.handleChange }
                                required={true}
                                instructions={ "Escolha um título curto e atraente sobre o que vocẽ faz." } 
                                pattern='.{3,64}'
                            />
                            
                            <Select
                                label='Categoria'
                                name="id_category"
                                placeholder="Selecione"
                                value={ this.state.fields.id_category }
                                changeValue={ this.handleChange }
                                required={true}
                                instructions={ "Escolha um título curto e atraente sobre o que vocẽ faz." } 
                                pattern='.{3,64}'
                                options={[
                                    {name:'Categoria 1', value: 1},
                                    {name:'Categoria 2', value: 2}
                                ]}
                            />

                            <InputRadio
                                label="Seu trabalho pode ser remoto?"
                                name='agree'
                                required={true}
                                title='Informe se seu trabalho pode ser feito online, pelo computador ou celular.'
                                instructions='Informe se seu trabalho pode ser feito online, pelo computador ou celular.'
                                changeValue={ this.handleChange }
                                options={[
                                    {name:'Sim', value: 1},
                                    {name:'Não, somente presencial', value: 0}
                                ]}
                                value=""
                            />

                            <div className='inputimage'>
                                <span>
                                    Escolha uma imagem no tamanho 800 x 600 pixels.
                                </span>
                            </div>
                            
                            <InputFile
                                label="Imagem principal"
                                name='poster'
                                required={true}
                                title='Insira uma imagem no tamanho 800 x 600 pixels.'
                                instructions='Insira uma imagem no tamanho 800 x 600 pixels.'
                                changeValue={ this.handleChange }
                                options={[
                                    {name:'Sim', value: 1},
                                    {name:'Não, somente presencial', value: 0}
                                ]}
                                value=""
                            />

                            <InputCheck
                                label="Li e aceito os termos de uso e privacidade"
                                name='agree'
                                required={true}
                                title='É necessário aceitar os Termos de Uso o Privacidade para adicionar um anúncio.'
                                instructions={<span>Leia aqui os <a href="/termos">Termos de Uso e Privacidade</a>.</span>}
                                changeValue={ this.handleChange }
                                options={[
                                    {name:'Aceito', value: 1},
                                    {name:'Não aceito', value: 0}
                                ]}
                                value="0"
                            />
                        </div>
                        <button type='submit'>Publicar o anúncio</button>
                    </form>
                </section>
            </main>
        );
    }
}

export default NewAd;