import React from 'react';
import { withRouter } from 'react-router-dom'
import { getPerson } from "../utils/service.js";
import Card from '../components/card.js';

class Person extends React.Component {

	constructor(props) {
		super(props);

		this.showError = this.showError.bind(this);
		this.hideError = this.hideError.bind(this);

        const path = this.props.location.pathname.split("/");

		const param = {
            username: path[1]
        }

		this.state = {
            param: param,
            data: null
		};

		this.props.setLoading(true);
	}


	async componentDidMount() {
		document.title = "Auati - " + this.state.param.username;
		let msg = '';
	    const result = await getPerson(this.state.param.username);
		console.log(result.data, this.state.param.username);

	    if (result && result.success) {
			this.setState({
                data: result.data
			});
	    } else {
            msg = result && result.data;
	    	this.showError(msg || 'Falha ao carregar os dados do servidor.');
	    }
       this.props.setLoading(false);
	}

	componentWillUnmount() {
		window.removeEventListener('beforeunload', this.handleLeavePage);
	}

	handleLeavePage(e) {
		const confirmationMessage = 'Deseja sair sem salvar as alterações?';
		e.returnValue = confirmationMessage;     // Gecko, Trident, Chrome 34+
		return confirmationMessage;              // Gecko, WebKit, Chrome <34
	}

	showError(msg) {
		this.setState({msg: msg});
	}
	
	hideError() {
		console.log(this.state.msg, 'hide');
		this.setState({msg: ''});
    }

	render() {
        const person = this.state.data;
		const Ads = person && person.ads.map(ad => (
			<Card ad={ad} key={ad.id} />
		));
		return person && (
			<main className="person">
				<article>
					<header>
						<figure className='avatar'>
							<img src={person.avatar} alt="" />
						</figure>
						<h1>
							<p>{person.nickname}</p>
							<small>{person.fullname}</small>
						</h1>
						<p className='about'>
							{person.about_me}
						</p>
					</header>
					<section className='person-ads'>
						{!person.ads.length && (
							<p>{person.nickname} ainda não tem anúncios.</p>
						)}
						{person.ads.length && (
							<>
							<h3>@{person.nickname} possui {person.ads.length} anúncio{person.ads.length > 1?'s':''}:</h3>
							<ul className='ads'>
								{Ads}
							</ul>
							</>
						)}
					</section>
				</article>
			</main>
		);
	}
}

export default withRouter(Person);