import React from 'react';
import GlobalContext from '../globalContext.js';
import { usernameExists, userByCipher, requestVerificationEmail } from "../utils/JWTAuth.js";
import { withRouter } from 'react-router-dom'
import Msg from '../components/msg';
import { handleChange, InputEmail, pickFields } from '../components/fields.js';


class VerifyEmail extends React.Component {

	constructor(props) {
		super(props);

		this.setMsg = this.setMsg.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);

		const path = this.props.location.pathname;
		this.username_from_url = path.split("/")[2];
        this.fieldNames = ['email'];
		this.state = {
			userdata: false,
			msg: {},
            fields: pickFields(null, this.fieldNames),
			status: 'pending'
		};
		this.props.setLoading(true);
	}

	static contextType = GlobalContext;

	async componentDidMount() {
		document.title = "Verificar e-mail - Auati";
        
        if (this.username_from_url) {
            let result = await usernameExists(this.username_from_url);
            let success = result && result.success;
            if (success) {
                result = await userByCipher(result.data.cipher);
                success = result && result.success;
            }
            if (success) {
				let fields = this.state.fields;
				const user = result.data[0];
				fields.email = user.email
                this.setState({ 
					userdata: user, 
					status: user.email_verified ? 'verified' : 'pending',
					fields
				});
            } else {
                this.setState({ msg: result.data });
            }
        }
		this.props.setLoading(false);
	}   

	setMsg(msg) {
		this.setState({msg});
	}
	
	handleInputChange(event) {
		const el = event.target;
		const name = el.name;
		const value = el.type === 'checkbox' ? (el.checked?1:0) : el.value;
		let data = this.state.data;
		data[name] = value;
		this.setState({ data: data });
		return el;
	}
	

	async handleSubmit(event) {
		event.preventDefault();
		this.props.setLoading(true);
		const result = await requestVerificationEmail({email: this.state.fields.email});
	    const success = result && result.success;
		if (success) {
			this.setState({ 
				msg: {
					text: 'Um e-mail de confirmação foi enviado para você.', 
					type: 'success'
				},
				status: 'sent' 
			});
		} else {
			this.setState({ 
				msg: {
					text: (result && result.data.text) || "Não foi possível solicitar o e-mail de verificação.", 
					type: result.data.code === 'email_alread_verified' ? 'success' : 'error'
				},
				status: result.data.code === 'email_alread_verified' ? 'verified' : 'fail' 
			});
		}
		this.props.setLoading(false);
	}

	
    handleChange(event) {
		return handleChange(event, this);
	}


	render() {
        // console.log(this.state);
        const userdata = this.state.userdata;
		return (
			<main className="verify">
				<h1>
					{this.state.status === 'sent' && (<span>Verifique seu e-mail</span>)}
					{this.state.status === 'pending' && (<span>Verificar e-mail</span>)}
					{this.state.status === 'verified' && (<span>E-mail verificado!</span>)}
				</h1>
				{this.state.status === 'sent' && (<section>
					<p>
						Abra o link que foi enviado para seu
						e-mail <b>{this.state.fields.email}</b>.
					</p>
				</section>)}
				{this.state.status === 'verified' && (<section>
					<p>
						Seu e-mail <b>{this.state.fields.email}</b> já está verificado.
					</p>
					{!this.context.logged && (
					<button type='button' onClick={() => this.context.openDialog('login')}>
						Fazer login
					</button>
					)}
					{this.context.logged && (
					<button type='button' onClick={() => document.location.href = '/'}>
						Procurar serviços para trocar
					</button>
					)}
				</section>)}
				{this.state.status === 'pending' && (<section>
                    {userdata && (
                        <form onSubmit={this.handleSubmit} encType="multipart/form-data" method="post">
                            <p>
                                Olá, {userdata.person.nickname}!
                            </p>
                            <p>
								Para prosseguir no Auati é necessário abrir o link 
								enviado para o e-mail <b>{userdata.email}</b>
                            </p>
							<p>
								Caso não tenha recebido, use o 
								botão abaixo para solicitar novamente.
							</p>
                            <button type='submit'>
								Enviar mensagem de verificação
							</button>
                        </form>
                    )}
                    {!userdata && (
                        <form onSubmit={this.handleSubmit} encType="multipart/form-data" method="post">
                            <p>
                                Para entrar no Auati é necessário confirmar 
								que você possui acesso ao e-mail cadastrado. 
                            </p>
                            <div className='fields'>
                                <InputEmail
                                name='email' 
                                label='E-mail'
                                instructions='Digite o e-mail que vocẽ utilizou para se cadastrar.'
                                placeholder='E-mail utilizado no cadastro'
								value={this.state.fields.email}
								changeValue={this.handleChange}
								required={true}
                                />
                            </div>
                            <button type='submit'>
                                Enviar mensagem de verificação
                            </button>
                        </form>
                    )}
				</section>)}
                    
				<Msg text={this.state.msg.text} type={this.state.msg.type} setMsg={this.setMsg}/>
			</main>
		);
	}
}

export default withRouter(VerifyEmail);