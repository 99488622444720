import React from "react";
import Poster from './poster.js';
import Actions from './actions.js';

class Card extends React.Component {

    Tag({tag, link}) {
        return tag ? (
            <span className='tag'>
                { link ? (<a href={`tag/${tag.slug}`}>{tag.name}</a>) : (tag.name) }
            </span>
        ) : "";
    }

    render() {

        const ad = this.props.ad;
        
        const classname = ad.plan || 'default';
        const want = ad.want_tags.map((tag, i) => (
            <React.Fragment key={tag.id}>{i > 0 && ", "}<this.Tag tag={tag} link={false} key={tag.id} /></React.Fragment>
        ));
        const offer = ad.offer_tags.map((tag, i) => (
            <React.Fragment key={tag.id}>{i > 0 && ", "}<this.Tag tag={tag} link={false} key={tag.id} /></React.Fragment>
        ));

        const accept_others = (ad.accept_others) ? <span className='accept_others'>Aceito receber propostas</span> : "";
        return (
            <li className={`card ${classname}`}>
                <a href={`/${ad.person.nickname}/${ad.slug}`}>
                    <Poster ad={ad} />
                    <header>
                        <h3>
                            <span>{ad.title}</span>
                        </h3>
                        <p className="description">
                            <b>Ofereço:</b> 
                            <span>{offer}</span>
                        </p>
                    </header>
                    <p className="accept">
                        {!!want.length && ( <b>Aceito:</b> )}
                        {want}{accept_others}
                    </p>
                </a>
                <Actions ad={ad} />
            </li>
        )
    }
}

export default Card;