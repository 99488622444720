import React from 'react';
import { withRouter } from "react-router-dom";
import { isLogged } from "../utils/JWTAuth.js";
import { getUsers, setUser } from "../utils/service.js";


class Favorites extends React.Component {
	constructor (props) {
		super(props);

		this.state = {
			data: [],
		};
	    this.props.setLoading(true); 
	}
	
	async componentDidMount() {
		document.title = "Auati - Favoritos";
		if (isLogged()) {
			let result = await getUsers(this.state.data.id);
			if (result && result.success) {
				let data = this.state.data;
				for(let prop in this.state.data) {
					if (result.data[prop]) {
						data[prop] = result.data[prop];
					}
				};
				this.setState({data: data });
			}
			if (!result || !result.success) {
				this.showError("Os dados não foram localizados.");
			}
			this.setState({ found: result && result.success });
		} else {
			this.props.history.push('/');
		}
	    this.props.setLoading(false); 
		window.addEventListener('beforeunload', this.handleLeavePage);
	}

	handleError(el, msg = 'Erro desconhecido') {
		el.classList.add('error');
		return this.showError(msg);
	}

	async handleSubmit(event) {
		event.preventDefault();
		this.props.setLoading(true);

		const form = event.target;
		const data = this.state.data;

		const formData = new FormData(form);
		const id = data.id;

		if (!formData.has("id")) {
			formData.append("id", id);
		}
		const result = await setUser(formData, id);
		if (!result.success) {
			return this.showError(result.data || 'Não foi possível salvar seus dados.');
		}
		this.setState({
		});
		localStorage.removeItem(this.storageName);
		this.props.setLoading(false);
		// for (var pair of formData.entries()) {
		//   console.log(pair[0]+ ', '+ pair[1]); 
		// }
		return this.showSuccess('Seus dados foram salvos');

	}

	handleInputChange(event) {
		const el = event.target;
		const name = el.name;
		const value = el.type === 'checkbox' ? (el.checked?1:0) : el.value;
		let data = this.state.data;
		data[name] = value;
		this.setState({ data: data });
		localStorage[this.storageName] = JSON.stringify(data);
		return el;
	}
	
	showError(msg) {
		this.setState({msg: ''});
		setTimeout(()=>this.setState({msg: msg, msgType: "error"}), 1);
	}

	hideError(msg) {
		this.setState({msg: ''});
	}

	showSuccess(msg) {
		this.setState({msg: ''});
		setTimeout(()=>this.setState({msg: msg, msgType: "success"}), 1);
	}
	
	hideSuccess(msg) {
		this.setState({msg: ''});
	}
	

	render() {
		return (
			<main className="favorites">
                <h1>
                    <span>Favoritos</span>
                </h1>
                <section>
                    Nenhum favorito foi adicionado ainda.
                </section>
			</main>
		) 
	}
}

export default withRouter(Favorites);