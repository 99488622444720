import axios from 'axios';
import { SERVER_URL } from './config.js';
import { getToken } from './JWTAuth.js';

const searchBy = async (object) => {
    let query = `?`;
    for (let property in object) {
        query += `${property}=${object[property]}`;
    }
    const endpoint = `${SERVER_URL}/search/?${query}`;
    try {
        
        let response = await axios.get(endpoint);
    	return (response.data);
        
    } 
    catch(e) { console.error(e); }
}

const getItems = async (path) => {
    console.log(SERVER_URL);
    const endpoint = SERVER_URL + path;
    const token = "Bearer "+getToken();
    try {
        let response = await axios({
            url: endpoint,
            method: 'GET',
            headers: {
                'Authorization': token
            }
        });
        return (response.data);
    } 
    catch(e) { console.error(e); }
}

const getAds = async param => { 
    param = param || '';
    if (typeof param === 'object') {
        param = "?" + new URLSearchParams(param).toString(); 
    } else {
        param = param ? ("/" + param) : "";
    }
    return getItems('ads' + param);
};

const getLikes = async id => getItems('likes' + (id ? '/' + id : ''));
const getMessages = async id => getItems('messages' + (id ? '/' + id : ''));
const getPerson = async id => getItems('person' + (id ? '/' + id : ''));

const getUsers = async id => getItems('usuarios' + (id ? '/' + id : ''));
const getNotifications = async id => getItems('user/notifications' + (id ? '/' + id : ''));
const getWPAds = async id => getItems('wpads' + (id ? '/' + id : ''));
const getHistory = async id => getItems('historico' + (id ? '/' + id : ''));
    

const delItem = async (id, path) => {
    if (!id) {
        return { success: false, data: 'Informações insuficientes'};
    }
    path = path + "/" + id;
    const endpoint = SERVER_URL+path;
    const token = "Bearer "+getToken();
    let data = {
        id: id,
        _METHOD: "DELETE"
    }
    try {
        let response = await axios({
            validateStatus: false,
            url: endpoint,
            method: 'DELETE',
            data: data,
            headers: {
                'Authorization': token
            }
        });
        return (response.data);
    } 
    catch(e) { return e; }
}

const delAuatiRequest = (id) => delItem(id, "auatirequest");    

const setItem = async (data, id, path) => {
    if (!id) {
        return { success: false, data: 'Informações insuficientes'};
    }
    path = path + "/" + id;
    const endpoint = SERVER_URL+path;
    const token = "Bearer "+getToken();
	data.append("_METHOD", "PUT");
    try {
        let response = await axios({
            validateStatus: false,
            url: endpoint,
            method: 'POST',
            data: data,
            headers: {
                'Authorization': token
            }
        });
        return (response.data);
    } 
    catch(e) { return e; }
}

const setLike = (data, id) => setItem(data, id, "likes");
const setAuati = (data, id) => setItem(data, id, "auati");
const setUser = (data, id) => setItem(data, id, "usuarios");

const addItem = async (data, path, auth = true, extra = {}) => {
    const endpoint = SERVER_URL+path;
    data.append("_METHOD", "POST");
    const config = {
        validateStatus: false,
        url: endpoint,
        method: 'POST',
        data: data,
        headers: {
            'Content-Type': "multipart/form-data"
        }
    };
    for (const key in extra) {
        config[key] = extra[key];
    }
    if (auth) {
        const token = "Bearer "+getToken();
        config.headers.Authorization = token;
    }
    try {
        let response = await axios(config);
        return (response.data);
    } 
    catch(e) { console.error(e); }
}
const addAuatiRequest = (data) => addItem(data, "auatirequest");
const addMessage = (data) => addItem(data, "messages");
const addAd = (data) => addItem(data, "ad");

const addUser = (data) => addItem(data, "users");

const getClassificacao = () => {

}

const getStatuses = async (setResponse) => {
    const endpoint = `${SERVER_URL}/statuses`;
    const token = "Bearer "+getToken();

    try {
        let response = await axios({
            url: endpoint,
            method: 'GET',
            headers: {
                'Authorization': token
            }
        });
        return (response.data);
    } 
    catch(e) { console.error(e); }
}

export {
    getAds,
    getLikes,
    getMessages,
    getNotifications, 
    getPerson,

    delAuatiRequest,

    setLike,
    setAuati,
    
    addMessage,
    addAd,

    searchBy,
    getClassificacao,
    getStatuses,
    getWPAds,
    addAuatiRequest,
    getUsers,
    addUser,
    setUser,
    getHistory
} 