import React from "react";
import Msg from "./msg.js";
import GlobalContext from '../globalContext.js';
import { addUser } from "../utils/service.js";
import { isLogged, getUserData } from "../utils/JWTAuth.js";

/**
 * TODO
 * Tratar mensagem de erro (depois que aparece, toca o som quando a gente digita)
 * Inserir uma ilustração nesta página
 */

class FormSignup extends React.Component {
    constructor(props) {
        super (props);

		this.timerId = null;
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.showError = this.showError.bind(this);
		this.hideError = this.hideError.bind(this);
		this.togglePassword = this.togglePassword.bind(this);
		this.PassRequirements = this.PassRequirements.bind(this);
		this.backToLogin = this.backToLogin.bind(this);

		const dataFromLocalStorage = localStorage.getItem("newUserData");

		let data = dataFromLocalStorage ? JSON.parse(dataFromLocalStorage) : {
			nickname: "",
			email: ""
		};

		this.state = {
			showPassword: false,
			data: data,
			msg: "",
            created: false,
            newUser: null
		};
        this.userData = getUserData();
        this.isLogged = isLogged();
        
    }

	static contextType = GlobalContext;

	togglePassword() {
		this.setState({showPassword: !this.state.showPassword});
	}

	async handleSubmit(event) {
		event.preventDefault();
		const form = event.target;
		const formData = new FormData(form);
		this.props.setLoading && this.props.setLoading(true);
		
		let result = await addUser(formData);
		if (result.success) {
			localStorage.removeItem("newUserData");
            this.setState({ 
                created: true ,
                newUser: result.data
            });
            this.context.setNewUser(result.data);
		} else {
			this.showError(result.data);
		}
		this.props.setLoading && this.props.setLoading(false);
	}

	handleInputChange(event) {
		const el = event.target;
		const name = el.name;
		const value = el.type === 'checkbox' ? (el.checked?1:0) : el.value;
		let data = this.state.data;
		data[name] = value;
		this.setState({ data: data });
		localStorage["newUserData"] = JSON.stringify(data);
		return el;
	}

    PassRequirements () {
        const pass = this.state.data.password;
        let min = /^.{8,}$/.test(pass);
        let upp = /(?=.*[A-Z])/.test(pass);
        let low = /(?=.*[a-z])/.test(pass);
        let num = /(?=.*[0-9])/.test(pass);
		const isValid = (min && upp && low && num);
        const format = (text, valid) => (valid ? text : <b style={{color:'var(--color-error)'}}>{text}</b>);
        return (<>

		{isValid && (<span style={{color:'var(--color-success)'}} >
            Esta é uma boa senha!
        </span>)}

		{!isValid && (<span>
            A senha deve ter no {format("mínimo 8 caracteres", min)}, {format("letras maiúsculas", upp)}, {format("minúsculas", low)} e {format("números", num)} .
            {min}
        </span>)}

        </>);
    }

	showError(msg) {
		this.setState({msg: ''});
		setTimeout(()=>this.setState({msg: msg}), 1);
	}
	
	hideError(msg) {
		this.setState({msg: ''});
	}

    backToLogin() {
        this.context.openDialog('login');
	}

    render() {
		const type = this.state.showPassword ? 'text' : 'password';
        return (<>
            {!this.state.created && (
            <form autoComplete="off" onSubmit={this.handleSubmit}>
                <fieldset>
                    <div className="fields">
                        
                        <div className='field input type-text nickname'>
                            <label htmlFor="nickname">Nome</label>
                            <input required 
                                id='nickname'
                                name='nickname' 
                                type="text" 
                                value={this.state.data.nickname} 
                                onChange={this.handleInputChange} 
                            />
                            <p>Escreva o seu apelido ou como gosta de ser chamado.</p>
                        </div>
                        
                        <div className='field input type-email email'>
                            <label>Seu e-mail</label>
                            <input required autoComplete="off" name='email' type="email" value={this.state.data.email} onChange={this.handleInputChange} />
                            <p>Enviaremos uma confirmação para seu e-mail.</p>
                        </div>
                        
                        {/* <div className='field input type-text cpf'>
                            <label>CPF</label>
                            <input required name='cpf' type="text" value={this.state.data.cpf} onChange={this.handleInputChange} />
                        </div>
                        
                        <div className='field input type-tel tel'>
                            <label>Celular</label>
                            <input  
                            id='tel' 
                            name='tel' 
                            type="tel" 
                            value={this.state.data.tel} 
                            onChange={this.handleInputChange} />
                        </div>
                        
                        <div className='field input type-text fullname'>
                            <label>Nome completo</label>
                            <input required name='fullname' type="text" value={this.state.data.fullname} onChange={this.handleInputChange} />
                        </div>
                            */}

                        <div className='field input password'>
                            <label htmlFor="password">Senha:</label>
                            <input required name='password' type={type} 
                                id='password'
                                value={this.state.data.password} 
                                onChange={this.handleInputChange} 
                                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$"
                                title="Crie uma senha segura, com no mínimo 8 caracteres, letras maiúsculas, minúsculas e números." 
                            />
                            <button type='button' className={'togglepass '+type} onClick={this.togglePassword}></button>
                            <p>
                                {<this.PassRequirements />}
                            </p>
                        </div>
                        
                        {this.userData && this.userData.role === "Admin" && (
                            <div className='field select type-role'>
                                <label>Atribuição do usuário</label>
                                <select required name='role' type="text" value={this.state.data.role} onChange={this.handleInputChange} >
                                    <option value="">Selecione o papel...</option>
                                    <option value="Autor">Autor - Apenas cria anúncios</option>
                                    <option value="Editor">Editor - Pode editar no backoffice</option>
                                    <option value="Admin">Admin - Acesso a todas as funcionalidades</option>
                                </select>
                                <p>Como <b>Admin</b> você pode criar usuários com privilégios diferenciados.</p>
                            </div>
                        )}

                    </div>
                </fieldset>
                
                <div className="buttons">
                    <button type="submit" disabled={this.props && this.props.loading}>
                        <span>Criar usuário</span>
                    </button>
                    <p>
                        Já possui uma conta? <button className="link" onClick={this.backToLogin}>Faça login</button>
                    </p>
                    {this.state.msg && <Msg text={this.state.msg} onClose={this.hideError} />}
                </div>
                
            </form>
            )}
            {this.state.created && (<>
            <h2>
                Confira seu e-mail
            </h2>
            <p>Sua conta foi criada.</p>
            <p>
                Basta abrir o e-mail que mandamos para <b>
                {this.state.newUser.email}</b> e clicar 
                no link de confirmação para ativar sua conta.
            </p>
            </>)}
        </>);
    }
}

export default FormSignup;