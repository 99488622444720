import React from 'react';

class Terms extends React.Component {
    render() {
        return  (
			<main className="terms">
                <h1>Termos de Uso</h1>
				<section>
                    Texto do termo.
                </section>
			</main>
		);
    }
}

export default Terms;