import React from 'react';
import { getAds } from "../utils/service.js";
import Card from './card.js';

// import { Link } from 'react-router-dom';

class AdList extends React.Component {
    
    constructor(props) {
        super(props)
    
        this.state = {
            ads: [],
            page: 0,
            loadingMore: false
        }
		this.showError = this.showError.bind(this);
		this.hideError = this.hideError.bind(this);		
		this.loadMore = this.loadMore.bind(this);		
    }
    
    async componentDidMount() {
        let result = await getAds();
        if (result && result.success) {
            this.setState({ads: result.data });
        }
        if (!result || !result.success) {
            this.showError("Nenhum anúncio foi localizado.");
            console.log(result);
        }
    }

    async loadMore() {
        const page = this.state.page + 1;
        this.setState({
            page: page,
            loadMore: true
        });
        let result = await getAds({
            p: page,
            q: 100
        });
        console.log(page);
        if (result && result.success) {
            this.setState({ads: [...this.state.ads, ...result.data] });
        }
        if (!result || !result.success) {
            this.showError("Nenhum anúncio foi localizado.");
        }
        this.setState({
            loadMore: false
        });
        
    }

    showError(msg) {
		this.setState({msg: msg});
	}
	
	hideError(msg) {
		this.setState({msg: ''});
	}

    render() {
        const Ads = this.state.ads.map(ad => (
          <Card ad={ad} key={ad.id} />
        ))
        return (
            <section className="adcover">
                <ul className="ads">
                    {Ads}
                </ul>
                <button type='button' className="more" onClick={this.loadMore}>Carregar mais anúncios...</button>
            </section>
        )
    }
}

export default AdList;