import React from 'react';
import { Link } from 'react-router-dom';

class Nav extends React.Component {
    
    constructor(props) {
        super(props);

        const logged = this.props.logged;

        this.state = {
            menuItems: [
                {
                    class: "myads",
                    link: "/meus-anuncios",
                    text: "Meus anúncios",
                    highlighted: true,
                    show: logged
                },
                {
                    class: "adsponsor",
                    link: "/seja-um-mantenedor",
                    text: "Quero ser um mantenedor",
                    highlighted: true,
                    show: false
                },
                {
                    class: "notifications",
                    link: "/notificacoes",
                    text: "Notificações",
                    highlighted: true,
                    show: logged
                },
                {
                    class: "messages",
                    link: "/mensagens",
                    text: "Mensagens",
                    highlighted: true,
                    show: logged
                },
                {
                    class: "favorites",
                    link: "/favoritos",
                    text: "Favoritos",
                    highlighted: true,
                    show: logged
                },
                {
                    class: "myaccount",
                    link: "/minha-conta",
                    text: "Minha conta",
                    highlighted: true,
                    show: logged
                },
                
                {
                    class: "invite",
                    link: "/convidar-amigos",
                    text: "Convidar amigos",
                    highlighted: false,
                    show: true
                },
                {
                    class: "terms",
                    link: "/termos-de-uso",
                    text: "Termos de uso",
                    highlighted: false,
                    show: true
                },
                {
                    class: "help",
                    link: "/ajuda",
                    text: "Ajuda e Feedback",
                    highlighted: false,
                    show: true
                },
                {
                    class: "about",
                    link: "/sobre-o-projeto",
                    text: "Sobre o projeto",
                    highlighted: false,
                    show: true
                },              
            ]
        };
    }

    render() {
        return (
            <nav>

                <ul>
                    {this.state.menuItems.map(o => (o.show && o.highlighted) && (
                    <li key={o.class} className={o.class}>
                        <Link to={o.link}>{o.text}</Link>
                    </li>
                    ))}
                </ul>

                <ul>
                    {this.state.menuItems.map(o => (o.show && !o.highlighted) && (
                    <li key={o.class} className={o.class}>
                        <Link to={o.link}>{o.text}</Link>
                    </li>
                    ))}
                    {this.props.logged && (<li className='logout'>
                        <button className="logout" onClick={this.props.logout}>Sair (logout)</button>
                    </li>)}
                </ul>

            </nav>
        )
    }
}

export default Nav;