import React from "react";


const InputText = ( {...args} ) => fieldInput(args, 'text');
const InputEmail = ( {...args} ) => fieldInput(args, 'email');
const InputTel = ( {...args} ) => fieldInput(args, 'tel');
const InputNumber = ( {...args} ) => fieldInput(args, 'number');
const InputDate = ( {...args} ) => fieldInput(args, 'date');


const fieldInput = (
    args, type
    ) => {
        let {
            name,
            label,
            placeholder,
            title,
            required,
            value,
            changeValue,
            instructions, 
            pattern,
            accept,
            limit
        } = args;
        //console.log(name, type, args);
        if (type === "date") {
            value = /[0-9-]+/.exec(value)[0];
        }
        return (name !== undefined) && (
        <div className={'field input' + type + " " + name }>
        
            {label && <label htmlFor={ name }>{ label }</label>}
            
            <input 
                name={ name } 
                id={ name } 
                type={ type } 
                required={ required } 
                value={ value } 
                onChange={ changeValue } 
                pattern={ pattern } 
                placeholder={ placeholder } 
                title={ title } 
                accept={ accept }
                maxLength={ limit }
                />
            <p>{ instructions }</p>
        
        </div>
    );
}


const InputFile = ( {...args} ) => {
    args['value'] = '';
    return fieldInput(args, 'file');
}


const InputImage = ( {...args} ) => {
    let {
        name,
        label,
        placeholder,
        title,
        required,
        value,
        changeValue,
        instructions, 
        entity,
        maxsize,
        accept
    } = args;
    
    const getAccept = () => {
        if (!accept) {
            return "image/*";
        }
        if (accept instanceof Array) {
            return accept.map(o => {
                switch (o) {
                    case 'svg': return "image/" + o + '+xml';
                    case 'jpg':
                    case 'jpeg': return "image/jpeg";
                    default: return "image/" + o;
                }
            }).join(', ');
        }
    };

    let handleChangeFile = (e) => {
        const input = e.target;
        if(input.files.length === 0) {
            return;
		}
        const file = input.files[0];
        let allowed_mime_types = [ 'image/jpeg', 'image/jpg', 'image/gif', 'image/svg+xml', 'image/png' ];
		maxsize = maxsize || 5;
		if(allowed_mime_types.indexOf(file.type) === -1) {
            alert('Escolha uma imagem no formato JPEG ou PNG. \nO arquivo escolhido estava no formato ' + file.type);
			return;
		}
		if(file.size > maxsize*1024*1024) {
            alert('Arquivo muito grande. \nEscolha um arquivo de até '+maxsize+'MB.');
			return;
		}
        const result = {
            name: input.name,
            value: file
        };
        changeValue(result);
        return result;
    }
    
    let setImg = () => {
        let src = '';
        if (typeof value === 'string') {
            src = 'images/' + entity + '/' + value;
        } else if (value instanceof File) {
            src = URL.createObjectURL(value);
        } else {
            return '';
        }
        return (<img src={ src } alt={ label } />);
    }

    return (
        <div className={'field inputimage ' + name}>
        
            {label && <label htmlFor={ name }>{ label }</label>}
            
            <figure className="image-view">
                {setImg()}
            </figure>

            <input name={ name } 
                id={ name } 
                type='file' 
                required={ required } 
                onChange={ handleChangeFile } 
                placeholder={ placeholder } 
                title={ title } 
                accept={ getAccept() }
                />
            <p>{ instructions }</p>
        
        </div>
    );
}


const Textarea = ( {...args} ) => {
    const {
        name,
        label,
        placeholder,
        title,
        required,
        value,
        changeValue,
        instructions, 
    } = args;

    return name && (
    <div className={'field textarea ' + name }>
    
        {label && <label htmlFor={ name }>{ label }</label>}
        
        <textarea name={ name } 
            id={ name } 
            required={ required } 
            value={ value } 
            onChange={ changeValue } 
            placeholder={ placeholder } 
            title={ title } 
            />
        <p>{ instructions }</p>
    
    </div>);
}


const Select = ( {...args} ) => {
    const {
        name,
        label,
        placeholder,
        title,
        required,
        value,
        changeValue,
        instructions, 
        options
    } = args;

    console.log(options);
    
    if (placeholder) {
        options.splice(0,0,{name: placeholder, value: ''});
    }
    console.log(options);
    return name && (
    <div className={'field select ' + name }>
    
        {label && <label htmlFor={ name }>{ label }</label>}
        
        <select name={ name } 
            id={ name } 
            required={ required } 
            value={ value } 
            onChange={ changeValue } 
            title={ title } 
            >
            {options.map(o => (<option key={o.value} value={o.value}>{o.name}</option>))}
        </select>
        <p>{ instructions }</p>
    
    </div>);
}


const InputRadio = ( {...args} ) => {
    const {
        name,
        label,
        title,
        required,
        value,
        changeValue,
        instructions, 
        options
    } = args;
    console.log(value, options, value === options[0].value);
    return name && (
    <div className={'field radio ' + name }>
    
        {label && <span htmlFor={ name }>{ label }</span>}
        <p>{ instructions }</p>
        
            {options.map(o => (
            <label key={o.value}>
                <input 
                    type="radio" 
                    value={ o.value } 
                    onChange={ changeValue } 
                    
                    name={ name } 
                    id={ name + '-' + o.value } 
                    required={ required } 
                    title={ title } 
                    />
                <span>{o.name}</span>
            </label>))}
    
    </div>);
}


const InputCheck = ( {...args} ) => {
    const {
        name,
        label,
        title,
        required,
        value,
        changeValue,
        instructions
    } = args;

    return name && (
    <div className={'field check ' + name }>
    
        <input 
            type="checkbox" 
            name={ name } 
            id={ name } 
            required={ required } 
            value={ value } 
            onChange={ changeValue } 
            title={ title } 
            />
        {label && <label htmlFor={ name }>{ label }</label>}
        <p>{ instructions }</p>
    
    </div>);
}


const updateFieldsOnLocalStorage = (identifier, fields) => {
    // console.log(identifier, fields);
    const stringFields = JSON.stringify(fields);
    if (!localStorage[identifier] || localStorage[identifier] === 'undefined') {
        localStorage[identifier] = stringFields;
    } else {
        if (stringFields !== localStorage[identifier]) {
            if(window.confirm('Há alterações não salvas. \nDeseja retomar a edição de onde parou?')) {
                fields = JSON.parse(localStorage[identifier]);
            } else {
                localStorage[identifier] = stringFields;
            }
        }
    }
    return fields;
}


const pickFields = (obj, fieldNames) => {
    const r = {};
    if (!obj) {
        fieldNames.forEach(o => r[o] = '');
        return r;
    } else {
        return ((obj) => {
            for(let i in fieldNames) {
                if (obj.hasOwnProperty(fieldNames[i])) {
                    r[fieldNames[i]] = obj[fieldNames[i]];
                }
            }
            return r;
        })(obj);
    }
}


const handleChange = (event, _this) => {
    let name = null;
    let value = null;
    if (event.target) {
        const el = event.target;
        name = el.name;
        switch (el.type) {
            case 'checkbox':
            value = el.checked ? 1 : 0;
            break;
            
            case 'radio':
            value = el.value;
            break;
    
            default:
            value = el.value;
            break;
        }
    } else {
        name = event.name;
        value = event.value;
    }
    let fields = _this.state.fields;
    console.log(name, value, fields[name], fields);
    if (!fields.hasOwnProperty(name)) {
        return;
    }
    fields[name] = value;
    _this.setState({ fields });
    localStorage['fieldsOrganization' + _this.state.id] = JSON.stringify(fields);
    // return el;
}


export {
    InputText,
    InputEmail,
    InputTel,
    InputNumber,
    InputDate,
    InputFile,
    InputImage,
    Textarea,
    Select,
    InputRadio,
    InputCheck,
    pickFields,
    updateFieldsOnLocalStorage,
    handleChange
};

/*
 {<InputRadio 
    label={`Você está cadastrado com o e-mail ${userdata.email}?`}
    name='myemail'
    options={[
        {name: 'Sim', value: 1},
        {name: 'Não', value: 0}
    ]}
    value={this.state.fields.myemail}
    changeValue={this.handleChange}
/>}
*/