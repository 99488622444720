import React, { Component } from 'react'

export class Ads extends Component {

	constructor(props) {
		super(props)
	
		this.state = {
			 
		}
	}
	
	render() {
		return (
			<div>
				
			</div>
		)
	}
}

export default Ads;
