import React from "react";

import FirstMessage from "../components/firstMessage";
// import Destaque from "../components/destaque";
// import Video from "../components/video";
// import Impacto from "../components/impacto";
import Instrucoes from "../components/instrucoes";
import Busca from "../components/busca";
import Categories from "../components/categories";
import AdList from "../components/adlist";

class Start extends React.Component {

	// constructor(props) {
	// 	super(props);
	// }

	componentDidMount() {
		document.title = "Auati - Seu trabalho, sua moeda";
	}
	
	render() {
		return (
			<main className="start">
				<FirstMessage />
				{/* <Destaque /> */}
				{/* <Video /> */}
				{/* <Impacto /> */}
				<Instrucoes />
				<Busca />
				<Categories />
				<AdList />
			</main>
		);
	}
}

export default Start;