import React from 'react';
import { withRouter } from 'react-router-dom'
import { getAds, getMessages } from "../utils/service.js";
import Poster from '../components/poster.js';
import Actions from '../components/actions.js';
import Galery from '../components/galery.js';
import Stars from '../components/stars.js';
import About from '../components/about.js';
import Ratings from '../components/ratings.js';
import GlobalContext from '../globalContext.js';
import ButtonRequestAuati from '../components/ButtonRequestAuati.js';


class Ad extends React.Component {

	constructor(props) {
		super(props);

		this.showError = this.showError.bind(this);
		this.hideError = this.hideError.bind(this);
		this.loadAd = this.loadAd.bind(this);

        const path = this.props.location.pathname.split("/");

		const param = {
            nickname: path[1],
            ad: path[2]
        }

		this.state = {
            param: param,
            ad: null,
			pending: false
		};

		this.props.setLoading(true);
	}

    static contextType = GlobalContext;

	async componentDidMount() {
		document.title = "Auati - " + this.state.param.ad;
	    await this.loadAd();
		if (this.state.pending) {
			window.addEventListener('beforeunload', this.handleLeavePage);
		}
       this.props.setLoading(false);
	}

	async loadAd() {
		let msg = '';
		const result = await getAds(this.state.param);
	    if (result && result.success) {
			let ad = result.data[0];
			if (ad.auati.length) {
				ad.auati.forEach(async (o, i) => {
					ad.auati[i]["messages"] = await (async () => {
						const responseMessages = await getMessages(o.id);
						return responseMessages.success ? responseMessages["data"] : [];
					})();
				});
			}
			console.log(ad.auati);
			this.setState({
				ad: ad
			});
			document.title = "Auati - " + ad.title;
	    } else {
            msg = result && result.data;
	    	this.showError(msg || 'Nenhum anúncio foi encontrado com o endereço informado.');
	    }
	}

	componentWillUnmount() {
		window.removeEventListener('beforeunload', this.handleLeavePage);
	}

	handleLeavePage(e) {
		const confirmationMessage = 'Deseja sair antes de concuir?';
		e.returnValue = confirmationMessage;     // Gecko, Trident, Chrome 34+
		return confirmationMessage;              // Gecko, WebKit, Chrome <34
	}

	showError(msg) {
		this.setState({msg: msg});
	}
	
	hideError() {
		console.log(this.state.msg, 'hide');
		this.setState({msg: ''});
    }

    ad(ad) {
		ad.ratings = [
			{
				id: 1,
				dateCreated: '2022-02-27 08:40:00',
				dateUpdated: '2022-02-27 08:40:00',
				userCreated: 1,
				userUpdated: 1,
				isTrash: false,
				idAuati: 1,
				idAd: ad.id,
				idPerson: 1,
				ratingAuati: 4,
				ratingPlatform: 3,
				report: {
					title: 'Excelente experiência',
					description: 'Quando iniciou os serviços imaginei que seriam feitos de forma convencional como a maioria dos serviços oferecidos pelos profissionais que fazem este tipo de serviço. Mas quando ele concluiu os serviços pude perceber que foram feitos de de uma maneira singular.'
				},
				useful: 3
			},
			{
				id: 2,
				dateCreated: '2022-02-27 08:40:00',
				dateUpdated: '2022-02-27 08:40:00',
				userCreated: 1,
				userUpdated: 1,
				isTrash: false,
				idAuati: 1,
				idAd: ad.id,
				idPerson: 1,
				ratingAuati: 4,
				ratingPlatform: 3,
				report: {
					title: 'Profissional exemplar',
					description: 'Quando iniciou os serviços imaginei que seriam feitos de forma convencional. Mas quando ele concluiu os serviços pude perceber que foram feitos de de uma maneira singular como a maioria dos serviços oferecidos pelo profissional.'
				},
				useful: 3
			},
			{
				id: 3,
				dateCreated: '2022-02-27 08:40:00',
				dateUpdated: '2022-02-27 08:40:00',
				userCreated: 1,
				userUpdated: 1,
				isTrash: false,
				idAuati: 1,
				idAd: ad.id,
				idPerson: 1,
				ratingAuati: 4,
				ratingPlatform: 3,
				report: {
					title: 'Excelente experiência',
					description: 'Comigo foi assim... Quando iniciou os serviços imaginei que seriam feitos de forma convencional como a maioria dos serviços oferecidos pelos profissionais que fazem este tipo de serviço. Mas quando ele concluiu os serviços pude perceber que foram feitos de de uma maneira singular.'
				},
				useful: 3
			}
		];
		ad.rating = 4.6;
		ad.bars = [
			{ stars: 5, amount: 12 }, 
			{ stars: 4, amount: 3 }, 
			{ stars: 3, amount: 6 }, 
			{ stars: 2, amount: 2 }, 
			{ stars: 1, amount: 1 } 
		];
		ad.totalStars = ad.bars.reduce((partial, o) => partial + o.amount, 0);
		ad.address = {
			id: 1,
			date_created: "2022-03-02 17:02",
			date_updated: "2022-03-02 17:02",
			user_created: 1,
			user_updated: 1,
			is_trash: false,
			default : false,
			country: "Brasil",
			zipcode: "29000",
			street: "Rua das Casas",
			number: "123",
			neighbourhood: "Bairro das Ruas",
			city: "Vitória",
			state: "ES",
			address_type: "home",
			lat: 123,
			lon: 123
		};
        return (<article>
            
			<Poster ad={ad} />
            
			<div className='moreinfo'>
				<h4>Mais informações</h4>
				<p>
				{ad.moreinformation}
				</p>
				<Galery ad={ad} />
            </div>
            
			<Ratings ad={ad}></Ratings>

			<div className='grades'>
				<h4>Distribuição das notas</h4>
				<ul className='bars'>
					{ad.bars.map((o, i) => (
					<li key={i}>
						<div className={'bar '+o.stars+'-stars'}>
							<div className={'rating-'+o.stars} style={{height: (o.amount / ad.totalStars * 100) +'%'}}>
							</div>
						</div>
						<span>{o.amount}</span>
					</li>
					))}
				</ul>
			</div>

			<div className='tabs reviews'>
				<div className='selector'>
					<button type='button' className='selected'>Todas {ad.ratings.length}</button>
					<button type='button'>Positivas {ad.ratings.length}</button>
					<button type='button'>Críticas {ad.ratings.length}</button>
				</div>
				<ul className='content'>
					{ad.ratings.map(o => (
					<li key={o.id} className='review'>
						<Stars ad={ad} />
						<button className='useful'>
							<span>Foi útil?</span>
						</button>
						<h3>{o.report.title}</h3>
						<p>{o.report.description}</p>
					</li>
					))}
				</ul>
			</div>
			
			<Actions ad={ad} />
			<section className='summary'>
				<h1>{ad.title}</h1>
				{ad.address && (<address>{ad.address.city}/{ad.address.state}</address>)}
				<div className='offer'>
					<h4>Ofereço</h4>
					<p>{ad.offer_tags.map(o => (<span key={o.id}>{o.name}</span>))}</p>
				</div>
				<div className='want'>
					<h4>Aceito</h4>
					<p>{ad.want_tags.map(o => (<span key={o.id}> {o.name} </span> ))}</p>
				</div>
				<ButtonRequestAuati ad={ad} loadAd={this.loadAd} />
				<div className='professional'>
					<About person={ad.person} links={ad.links} />
				</div>
			</section>

        </article>);
    }

	relatedAds() {
		return (
		<aside className='related-ads'>
			<h4>Anúncios relacionados</h4>
			<div>
				Aqui entram alguns relacionados
			</div>
		</aside>
		);
	}

	

	render() {
        const ad = this.state.ad;
		return (
			<main className="ad">
				{ad && (
					this.ad(ad) 
				)}
				{this.relatedAds()}
			</main>
		);
	}
}

export default withRouter(Ad);

/*
var data_ad = {
	"success": true,
	"data": [
		{
			"id": 2,
			"date_created": "2022-01-15 07:30:08",
			"date_updated": "2022-01-15 07:30:08",
			"user_created": "",
			"user_updated": "",
			"is_trash": 0,
			"id_person": 2,
			"id_category": 1,
			"title": "Atelier Joyce Lelis",
			"slug": "atelier-joyce-lelis",
			"accept_others": 1,
			"want_all_categories": "",
			"moreinformation": "Serviços de confeitaria, bolos para festas e eventos",
			"remote": "",
			"id_address": 0,
			"current_rating": "",
			"status": "draft",
			"date_posted": "",
			"id_plan": 1,
			"id_wp": 170,
			"id_pipefy": 0,
			"poster": "poster_2877.png",
			"poster_schema": "",
			"agree": "",
			"person": {
				"id": 2,
				"date_created": "2022-01-15 07:30:08",
				"date_updated": "2020-05-16 20:57:13",
				"user_created": "",
				"user_updated": "",
				"is_trash": 0,
				"id_user": 2,
				"type": "PF",
				"fullname": "Atelier Joyce Lelis",
				"nickname": "atelier232",
				"gender": "",
				"doc": "",
				"doc_verified": 0,
				"avatar": "",
				"did_exchange_before": "",
				"how_did_you_find_out": "",
				"id_address": 0,
				"about_me": "Ofereço: \n Serviços de confeitaria, bolos para festas e eventos\n \n \nEstou aberta a ofertas.",
				"id_reputation": "",
				"pipefy_id": ""
			},
			"category": {
				"id": 1,
				"date_created": "2022-01-15 07:30:08",
				"date_updated": "",
				"user_created": "",
				"user_updated": "",
				"is_trash": 0,
				"id_category": "",
				"name": "Saúde e Bem Estar",
				"slug": "saude-e-bem-estar",
				"id_icon": "",
				"color": ""
			},
			"images": [],
			"offer_tags": [
				{
					"id": 3,
					"date_created": "2022-01-15 07:30:08",
					"date_updated": "",
					"user_created": "",
					"user_updated": "",
					"is_trash": 0,
					"name": "Serviços de confeitaria, bolos para festas e eventos",
					"slug": "servicos-de-confeitaria-bolos-para-festas-e-eventos"
				}
			],
			"want_tags": [],
			"want_categories": [],
			"links": [
				{
					"id": 1,
					"date_created": "2022-01-15 07:30:08",
					"date_updated": "",
					"user_created": "",
					"user_updated": "",
					"is_trash": "",
					"id_ad": 2,
					"id_linktype": 1,
					"value": "ateliejoycelelis",
					"link_verified": "",
					"name": "Perfil no Instagram",
					"type": "instagram",
					"urlprefix": "https:\/\/instagram.com\/",
					"show": ""
				}
			],
			"requests": [],
			"address": "",
			"plan": "",
			"ratings": [],
			"likes": 1,
			"liked": [
				{
					"id": 2,
					"date_created": "2022-06-13 08:59:29",
					"date_updated": "",
					"user_created": "",
					"user_updated": "",
					"is_trash": 0,
					"id_ad": 2,
					"id_user": 18,
					"active": 1
				}
			],
			"views": 0
		}
	]
}
*/