import React from 'react';

class MyAds extends React.Component {

    goToNewAd() {
        window.location.assign('/novo-anuncio');
    }

    render() {
        const ad = null;
        return  (
			<main className="myads">
                <section>
                    <h1>Meus anúncios</h1>
                    {!ad && (
                        <div className='empty'>
                            <p>
                                Você ainda não possui anúncios.
                            </p>
                            <p>
                                O que você sabe fazer? Crie um anúncio agora mesmo!
                            </p>
                            <button type='submit' onClick={this.goToNewAd}>Começar</button>
                        </div>
                    )}
                </section>
			</main>
		);
    }
}

export default MyAds;