import React from 'react';
// import { Link } from 'react-router-dom';

class Busca extends React.Component {
    
    render() {
        return (
            <section className="busca">

            </section>
        )
    }
}

export default Busca;