import React from 'react';
// import { Trans, withTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import SearchField from './SearchField';

class Categories extends React.Component {

    constructor(props) {
        super(props);

        this.categories = [
            {   
                "id": 1,
                "name": "Alimentos",
                "color": "orange",
                "icon": "images/categories/alimentos.png",
                "link": "/c/alimentos"
            },
            {   
                "id": 2,
                "name": "Animais",
                "color": "darkseagreen",
                "icon": "images/categories/animais.png",
                "link": "/c/animais"
            },
            {   
                "id": 3,
                "name": "Arte e Cultura",
                "color": "red",
                "icon": "images/categories/arte-e-cultura.png",
                "link": "/c/arte-e-cultura"
            },
            {   
                "id": 4,
                "name": "Beleza",
                "color": "palevioletred",
                "icon": "images/categories/beleza.png",
                "link": "/c/beleza"
            },
            {
                "id": 5,
                "name": "Comunicacao e Design",
                "color": "yellowgreen",
                "icon": "images/categories/comunicacao-e-design.png",
                "link": "/c/comunicacao-e-design"
            },
            {   
                "id": 6,
                "name": "Consultoria",
                "color": "steelblue",
                "icon": "images/categories/consultoria.png",
                "link": "/c/consultoria"
            },
            {   
                "id": 7,
                "name": "Ensino e Educacao",
                "color": "orange",
                "icon": "images/categories/endino-educacao.png ",
                "link": "/c/endino-educacao"
            },
            {   
                "id": 8,
                "name": "Inovação",
                "color": "purple",
                "icon": "images/categories/inovacao.png",
                "link": "/c/inovacao"
            },
            {   
                "id": 9,
                "name": "Música",
                "color": "green",
                "icon": "images/categories/musica.png",
                "link": "/c/musica"
            },
            {   
                "id": 10,
                "name": "Saude e bem estar",
                "color": "#1ce6e6",
                "icon": "images/categories/saude-e-bem-estar.png",
                "link": "/c/saude-e-bem-estar"
            },
            {   
                "id": 11,
                "name": "Servicos e Manutenção",
                "color": "#6e3ab3",
                "icon": "images/categories/servicos-e-manutencao.png",
                "link": "/c/servicos-e-manutencao"
            },
            {   
                "id": 12,
                "name": "Tecnologia e Desenvolvimento",
                "color": "darkkhaki",
                "icon": "images/categories/tecnologia-e-desenvolvimento.png",
                "link": "/c/tecnologia-e-desenvolvimento"
            },
            {   
                "id": 13,
                "name": "Vestuario",
                "color": "#bb3006",
                "icon": "images/categories/vestuario.png",
                "link": "/c/vestuario"
            }
        ];
    }

    Item(data) {
        const c = data.category;
        return (
            <li>
                <Link to={c.link}>
                    <figure style={{backgroundColor: c.color}}>
                        <img src={c.icon} alt={c.name}/>
                    </figure>
                    <h4>
                        {c.name}
                    </h4>
                </Link>
            </li>
        )
    }
    
    render() {
        return (
            <section className="categories">    
                
                <SearchField/>

                <ul>
                    { this.categories.map(c => <this.Item key={c.id} category={c} />) }
                </ul>
            </section>
        )
    }
}

export default withTranslation()(Categories);