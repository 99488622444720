import React, { Component, Suspense } from 'react';
import './App.css';
import { 
    isLogged, 
    getUserData, 
    logout 
} from "./utils/JWTAuth.js";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from 'react-router-dom';

import Dialog from './components/dialog';
import Formlogin from './components/formlogin';
import Formforget from './components/formforget';
import FormSignup from './components/formsignup';
import Recover from "./pages/recover";
import Confirm from "./components/confirm";
import Top from "./components/top";
// import Footer from "./components/footer";

import Start from "./pages/start";
import NewAd from "./pages/newad";
import Ad from "./pages/ad";
import Person from "./pages/person";
import AdDone from "./pages/addone";
import Ads from "./pages/ads";

import Myaccount from "./pages/myaccount";
import Users from "./pages/users";
import User from "./pages/user";
import Newuser from "./pages/newuser";
import Error404 from "./pages/error404";
import {GlobalProvider} from './globalContext';
import Msg from "./components/msg.js";
import MyAds from './pages/myads';
import Notifications from './pages/notifications';
import Messages from './pages/messages';
import Favorites from './pages/favorites';
import Invite from './pages/invite';
import Terms from './pages/terms';
import Help from './pages/help';
import About from './pages/about';
import VerifyEmail from './pages/verify-email';

class App extends Component {

    constructor(props) {
        super (props);
        
        this.setLoading = this.setLoading.bind(this);
        this.setUploadProgress = this.setUploadProgress.bind(this);
        this.setAccepted = this.setAccepted.bind(this);
        this.setFilterStatus = this.setFilterStatus.bind(this);
        this.setLoginStatus = this.setLoginStatus.bind(this);
        this.openDialog = this.openDialog.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.doLogout = this.doLogout.bind(this);
        this.doLogin = this.doLogin.bind(this);
        this.doForget = this.doForget.bind(this);
        this.doSignup = this.doSignup.bind(this);        
        this.setEmail = this.setEmail.bind(this);
        this.getEmail = this.getEmail.bind(this);        
        this.setSearch = this.setSearch.bind(this);
        this.setMsg = this.setMsg.bind(this);
        this.setNewUser = this.setNewUser.bind(this);
        
        this.state = {
            logged: isLogged(),
            userData: getUserData(),
            email: '',
            dialogData: null,
            loading: false,
            accepted: false,
            redirect: false,
            redirectPath: '',
            filterStatus: '',
            search: '',
            uploadProgress: 0,
            msg: {
                text: '',
                type: ''
            },
            newUser: null
        };
        

    }
    
    doLogout() {
        this.setState({ 
            logged: !logout(),
            redirect: true,
            redirectPath: "/"
        });
    }

    doLogin(result) {
        let success = result && result.success;
        let path = success ? '/' : '/';
        console.log(success);
        if (success) {
            this.setState({ 
                logged: success,
                redirect: true,
                redirectPath: path,
                userData: success ? getUserData() : ''
            });
        }
    }

    setNewUser(data) {
        this.setState({newUser: data})
    }
    
    doForget(result) {
        let success = result && result.success;
        this.setState({ loginDialog: success })
    }

    doSignup(result) {
        let success = result && result.success;
        this.setState({ loginDialog: success })
    }

    setLoading(isLoading) {
        this.setState({ loading: isLoading });
    }
    
    setUploadProgress(value) {
        this.setState({ uploadProgress: value });
    }

    setEmail(email) {
        this.setState({ email: email });
    }

    getEmail() {
        return this.state.email;
    }

    setLoginStatus(value) {
        this.setState({ 
            redirect: true,
        });
    }
    
    setMsg(text, type) {
        console.log(text, type);
        this.setState({
            msg: {
                text: text,
                type: type || 'error'
            }
        });
    }

    setDataDialog(type) {
        switch (type) {
            case 'login':
                return {
                    name: 'login',
                    title: 'Acesse a sua conta',
                    modal: false,
                    content: <Formlogin 
                        action={this.doLogin} 
                        close={this.closeDialog} 
                        email={this.state.email}
                        setEmail={this.setEmail}
                        loading={this.state.loading}
                        setLoading={this.setLoading} 
                    />
                }
            
            case 'forget':
                return {
                    name: 'forget',
                    title: 'Recuperar senha',
                    modal: false,
                    content: <Formforget 
                        action={this.doForget} 
                        close={this.closeDialog} 
                        getEmail={this.getEmail}
                        setEmail={this.setEmail}
                        loading={this.state.loading}
                        setLoading={this.setLoading} 
                    />
                }
                
            case 'signup':
                return {
                    name: 'signup',
                    title: 'Criar conta',
                    modal: false,
                    content: <FormSignup 
                        action={this.doSignup} 
                        close={this.closeDialog} 
                        getEmail={this.getEmail}
                        setEmail={this.setEmail}
                        loading={this.state.loading}
                        setLoading={this.setLoading} 
                    />
                }
            default: return null;
        }
    }

    openDialog(data) {
        if (typeof data === 'string') {
            data = this.setDataDialog(data);
        }
        if (data) {
            this.setState({
                dialogData: data,
            });
        }
    }
    
    closeDialog() {
        this.setState({dialogData: null})
    }

    setFilterStatus(value) {
        if (value === this.state.filterStatus) {
            value = {};
        }
        this.setState({ filterStatus: value});
    }

    setSearch(value) {
        this.setState({search: value});
    }
    
    setAccepted(value) {
        this.setState({ accepted: value });
    }
      
    renderRedirect() {
        let path = this.state.logged ? '/' : '/';
        if (this.state.redirect) {
            return <Redirect to={path} />
        }
    }

    renderRedirect2() {
        let path = this.state.redirectPath;
        if (this.state.redirect) {
            return <Redirect to={path} />
        }
    }

    bodyClasses(toApply, className) {
        if (toApply) {
            document.body.classList.add(className);
        } else {
            document.body.classList.remove(className);
        }
    }

    render() {
        this.bodyClasses(this.state.logged, 'logged');
        return (
        <GlobalProvider value={{
            loginDialog: this.openLoginDialog,
            setMsg: this.setMsg,
            openDialog: this.openDialog,
            closeDialog: this.closeDialog,
            newUser: this.state.newUser,
            setNewUser: this.setNewUser,
            logged: this.state.logged
            }}>
        <Suspense>
        <React.Fragment>
            <Router basename={process.env.PUBLIC_URL}>
                <Top 
                Router={Router}
                logged={this.state.logged} 
                setLoginStatus={this.setLoginStatus} 
                filterStatus={this.state.filterStatus}
                userData={this.state.userData}
                setFilterStatus={this.setFilterStatus}
                doLogout={this.doLogout}
                openLoginDialog={this.openLoginDialog}
                setSearch={this.setSearch}
                />
                
                {this.renderRedirect()}

                {this.renderRedirect2()}
                
                <Switch>
                    <Route exact path="/">
                        <Start />
                    </Route>
                   
                    <Route path="/meus-anuncios">
                        <MyAds
                        setLoading={this.setLoading} 
                        loading={this.state.loading}
                        />
                    </Route>

                    <Route path="/novo-anuncio">
                        <NewAd 
                        setLoading={this.setLoading} 
                        loading={this.state.loading}
                        />
                    </Route>

                    <Route path="/recover/:hash">
                        <Recover 
                        setLoading={this.setLoading} 
                        loading={this.state.loading}
                        openLoginDialog={this.openLoginDialog}
                        closeLoginDialog={this.closeLoginDialog}
                        />
                    </Route>

                    <Route path="/verify-email/:username?">
                        <VerifyEmail 
                        setLoading={this.setLoading} 
                        loading={this.state.loading}
                        />
                    </Route>

                    <Route path="/confirm/:hash">
                        <Confirm 
                        setLoading={this.setLoading} 
                        loading={this.state.loading}
                        />
                    </Route>

                    <Route path="/signup">
                        <Newuser 
                        logged={this.state.logged} 
                        setLoginStatus={this.setLoginStatus} 
                        loading={this.state.loading}
                        setLoading={this.setLoading} 
                        userData={this.state.userData}
                        />
                    </Route>

                    <Route path="/:user/:ad">
                            <Ad 
                            setLoading={this.setLoading} 
                            loading={this.state.loading}
                            />
                    </Route>

                    <Route path="/notificacoes">
                        <Notifications 
                        setLoading={this.setLoading} 
                        loading={this.state.loading}
                        />
                    </Route>
                         
                    <Route path="/mensagens">
                        <Messages 
                        setLoading={this.setLoading} 
                        loading={this.state.loading}
                        />
                    </Route>
                         
                    <Route  path="/favoritos">
                        <Favorites 
                        setLoading={this.setLoading} 
                        loading={this.state.loading}
                        />
                    </Route>

                    <Route  path="/convidar-amigos">
                        <Invite 
                        setLoading={this.setLoading} 
                        loading={this.state.loading}
                        />
                    </Route>

                    <Route  path="/termos-de-uso">
                        <Terms 
                        setLoading={this.setLoading} 
                        loading={this.state.loading}
                        />
                    </Route>
                             
                    <Route  path="/ajuda">
                        <Help 
                        setLoading={this.setLoading} 
                        loading={this.state.loading}
                        />
                    </Route>
                             
                    <Route  path="/sobre-o-projeto">
                        <About 
                        setLoading={this.setLoading} 
                        loading={this.state.loading}
                        />
                    </Route>
                                        
                    <Route path="/ads">
                        <Ads 
                        filterStatus={this.state.filterStatus}
                        setFilterStatus={this.setFilterStatus} 
                        logged={this.state.logged} 
                        setLoginStatus={this.setLoginStatus} 
                        loading={this.state.loading}
                        setLoading={this.setLoading} 
                        search={this.state.search}
                        userData={this.state.userData}
                        />
                    </Route>

                    <Route exact path="/:user">
                        <Person 
                        setLoading={this.setLoading} 
                        loading={this.state.loading}
                        />
                    </Route>

                    <Route path="/anuncio-concluido">
                        <AdDone
                        />
                    </Route>

                    <Route path="/meus-dados">
                        <Myaccount 
                        logged={this.state.logged} 
                        setLoginStatus={this.setLoginStatus} 
                        loading={this.state.loading}
                        setLoading={this.setLoading} 
                        />
                    </Route>
                    <Route path="/usuarios">
                        <Users 
                        logged={this.state.logged} 
                        setLoginStatus={this.setLoginStatus} 
                        loading={this.state.loading}
                        setLoading={this.setLoading} 
                        userData={this.state.userData}
                        />
                    </Route>
                    <Route path="/usuario">
                        <User 
                        logged={this.state.logged} 
                        loading={this.state.loading}
                        setLoading={this.setLoading} 
                        userData={this.state.userData}
                        />
                    </Route>
                    <Route>
                        <Error404 />
                    </Route>
                </Switch>

				{/* <Footer /> */}


                {this.state.dialogData &&
                    <Dialog 
                    data={this.state.dialogData} 
                    onClose={this.closeDialog} 
                    /> 
                }

                {this.state.shareDialog && 
                    <Dialog 
                    data={this.shareDialogData} 
                    onClose={this.closeShareDialog} 
                    /> 
                }
            </Router>
            {this.state.loading && (
                <div className="loading">
                {this.state.uploadProgress > 0 && this.state.uploadProgress < 100 && (
                    <div className="upload-progress">
                        <progress value={this.state.uploadProgress} max="100"></progress>
                        <p className="porcent">
                            <span className="raise" style={{height: (100 - this.state.uploadProgress)+'%'}}></span>
                            {this.state.uploadProgress}%
                        </p>
                    </div>
                )}
                {(this.state.uploadProgress === 0 || this.state.uploadProgress === 100) && (
                    <p className="waiting">Aguarde enquanto estamos processando...</p>
                    )}
            </div>
            )}
            {this.state.msg.text && <Msg text={this.state.msg.text} type={this.state.msg.type} setMsg={this.setMsg} />}
        </React.Fragment>
        </Suspense>
        </GlobalProvider>
    );
  }
}

export default App;