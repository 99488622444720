import React from 'react';
import GlobalContext from '../globalContext.js';
import DialogResponseAuati from './dialogResponseAuati.js';
import DialogChat from './dialogChat.js';
import { addAuatiRequest, delAuatiRequest } from "../utils/service.js";
import { getUserData } from "../utils/JWTAuth.js";
import { formatDate } from '../utils/convert.js';


class ButtonRequestAuati extends React.Component {

    constructor(props) {
        super(props);

        this.sendRequest = this.sendRequest.bind(this);
		this.requestAuati = this.requestAuati.bind(this);
		this.waitingResponse = this.waitingResponse.bind(this);
		this.removeRequest = this.removeRequest.bind(this);
		this.responseAuati = this.responseAuati.bind(this);
		this.openChat = this.openChat.bind(this);
		this.setOption = this.setOption.bind(this);

        this.state = {
            loading: false,
            optionSelected: '',
        }
    }

	static contextType = GlobalContext;

    handleChange = (event) => {
        const { target: { name, value } } = event;
        this.setState({ [name]: value });
    }

    setOption(status) {
        this.setState({optionSelected: status});
    }

    async sendRequest(event) {
		event.preventDefault();
        this.setState({
            loading: true
        });
        const data = getUserData();
        if (data.id) {
            const formData = new FormData();
            formData.append('id_user',  data.id);
            formData.append('id_ad',  this.props.ad.id);
            formData.append('text',  this.state.text || '');
            const result = await addAuatiRequest(formData);
            if (!result.success) {
                this.setState({ loading: false });
                return this.context.setMsg('Não foi possível solicitar o Auati.', 'error');
            }
            this.context.setMsg('O Auati foi solicitado. Aguarde a resposta.', 'success');
            this.props.loadAd();
            this.setState({ 
                loading: false 
            });
        } else {
            this.context.openDialog('login');
        }
        this.context.closeDialog();
        this.setState({
            loading: false
        });
        
    }

    async removeRequest(event, auati) {
        console.log(event, auati);
		event.preventDefault();
        this.setState({ loading: true });
        const data = getUserData();
        if (data.id) {
            const result = await delAuatiRequest(auati.id);
            
            if (!result.success) {
                this.setState({ loading: false });
                return this.context.setMsg('Não foi possível cancelar a solicitação de Auati.', 'error');
            }
            this.context.setMsg('A solicitação de Auati foi cancelada.', 'success');
            this.props.loadAd();
            this.setState({ loading: false });
        } else {
            this.context.openDialog('login');
        }
        this.context.closeDialog();
        this.setState({
            loading: false
        });
    }
    waitingResponse(auati) {
        const data = getUserData();
        if (data.id) {
            const ad = this.props.ad;
            this.context.openDialog({
                name: 'waitingDialog',
                title: 'Aguardando resposta',
                modal: false,
                content: (
                <div className='waiting'>
                    <p>
                        Uma solicitação de Auati foi enviada para <b>{ad.person.fullname}</b> em {formatDate(ad.auati[0].date_created)}. 
                    </p>
                    <p>
                        Você será notificado quando a solicitação for respondida.
                    </p>
                    <form>
                        <button type='button' className='bad' onClick={(e) => this.removeRequest(e, auati)}>Remover minha solicitação</button>
                    </form>
                </div>
            )});
        } else {
            this.context.openDialog('login');
        }

    }

	requestAuati() {
        const data = getUserData();
        if (data.id) {
            const ad = this.props.ad;
            const person = data.person;
            const link_ad = <a target="_blank" rel="noopener noreferrer" href={"https://auati.com.br/"+ad.person.nickname+"/"+ad.slug}>{ad.title}</a>;
            const link_person = <a target="_blank" rel="noopener noreferrer" href={"https://auati.com.br/"+person.nickname}>{person.fullname}</a>;
            this.context.openDialog({
                name: 'requestDialog',
                title: 'Solicitar um Auati',
                modal: false,
                content: (
                <div className='auati-request'>
                    <div>
                        Esta mensagem será enviada para <b>{ad.person.fullname}</b>:
                    </div>
                    
                    <p className='message preview'>
                        Olá, {ad.person.fullname}!<br />
                        {link_person} viu seu anúncio {link_ad} e gostaria de trocar serviços com você. <br />
                        Quem sabe vocês fazem um Auati?
                    </p>
                    
                    <form onSubmit={this.sendRequest}>
                        <div className='field'>
                            <label>Escreva mais (opcional):</label>
                            <textarea 
                                name="text"
                                id="text"
                                value={this.state.text} 
                                placeholder='Escreva aqui sua proposta de troca'
                                onChange={this.handleChange}
                            ></textarea>
                        </div>
                        <footer>
                            <div className="buttons">
                                <button type='submit' disabled={this.state.loading}>
                                    Enviar solicitação
                                </button>
                            </div>
                        </footer>
                    </form>
                </div>
            )});
        } else {
            this.context.openDialog('login');
        }
	}

    AdEdit () {

    }

    AdStatistics() {

    }

    openChat(auati) {
        const data = getUserData();
        if (data.id) {
            const ad = this.props.ad;
            this.context.openDialog({
                name: 'chatDialog',
                title: 'Negocie a troca de serviços',
                modal: false,
                content: <DialogChat 
                    ad={ad}
                    auati={auati}
                    setOption={this.setOption}
                    optionSelected={this.state.optionSelected}
                    loadAd={this.props.loadAd}
                    />
            });
        } else {
            this.context.openDialog('login');
        }
    }
    
    responseAuati(auati) {
        if (auati.status === 'accepted') {
            return this.openChat(auati);
        }
        const data = getUserData();
        if (data.id) {
            const ad = this.props.ad;
            let options = [
                {
                    status: 'rejected',
                    text: 'Recusar',
                    class: ['bad', this.state.setOption].join(" ")
                },
                {
                    status: 'accepted',
                    text: 'Aceitar',
                    class: ['good', this.state.setOption].join(" ")
                }
            ];
            this.context.openDialog({
                name: 'responseDialog',
                title: 'Você tem interesse?',
                modal: false,
                content: <DialogResponseAuati 
                    ad={ad}
                    auati={auati}
                    options={options}
                    setOption={this.setOption}
                    optionSelected={this.state.optionSelected}
                    loadAd={this.props.loadAd}
                    />
            });
        } else {
            this.context.openDialog('login');
        }
    }

    textStatus(auati) {
        switch (auati.status) {
            
            case 'accepted':
                return 'Em negociação';
                    
            case 'rejected':
                return 'Recusado';
        
            case 'agreed':
                return 'Em execução';
        
            case 'rating':
                return 'Em avaliaçao';
        
            case 'done':
                return 'Concluído';
                
            default:
                return 'Novo';
        }
    }

    render() {;
        const ad = this.props.ad;
        const user = getUserData();
        const datePosted = (!ad.date_posted || ad.date_posted === '0000-00-00 00:00:00') ? ad.date_created : ad.date_posted;
        const posted = formatDate((datePosted), "short");
        let html = '';

        // is my ad 
        if (ad.person.id_user === user.id) {
            let auatiList = '';
            if (ad.auati) {
                auatiList = (<div className='auati list'>
                    <h3>Auatis em andamento:</h3>
                    <ul>
                    {ad.auati.map(o => {
                        let status = this.textStatus(o);
                        return (
                            <li key={o.id} className={o.status}>
                                <div className=''>
                                    <button type='button' disabled={o.status==='rejected'} onClick={() => this.responseAuati(o)}>{o.person_requester.fullname} ({status})</button>
                                </div>
                            </li>
                        )
                    })}
                    </ul>
                </div>);
            }
            html = (
            <>
                <div className='auati my'>
                    <h3 title={'Publicado em: '+posted}>Este anúncio é seu!</h3>
                    <button type='submit' onClick={this.AdEdit}>Editar anúncio</button>
                    <button type='button' onClick={this.AdStatistics}>Ver estatísticas</button>
                    {this.state.optionSelected}
                </div>
                {auatiList} 
            </>
            );

        } else {
            
            if (ad.auati.length) {
                let auati = ad.auati[0];
                html = "teste";

                if (auati.id_user_requester === user.id) {
                    if (auati.status === "requested") {
                        html = (
                        <div className='auati requested'>
                            <h3>Aguardando resposta</h3>
                            <p>
                                Você enviou uma solicitação de Auati para <b>{ad.person.fullname}</b>.
                            </p>
                            <p>
                                Uma notificação será enviada para você quando a solicitação for respondida.
                            </p>
                            <button type='button' onClick={() => this.waitingResponse(auati)}>Mais informações</button>
                        </div>
                        );
                    }
                    if (auati.status === "rejected") {
                        html = (
                        <div className='auati rejected'>
                            <h3>Solicitação recusada</h3>
                            <p>
                                Sua solicitação de Auati não foi aceita por <b>{ad.person.fullname}</b>.
                            </p>
                            <h5>Resposta à solicitação:</h5>
                            <p>
                                <em>{auati.messages[auati.messages.length - 1].text}</em> 
                            </p>
                        </div>
                        );
                    }
                    if (auati.status === "accepted") {
                        html = (
                        <div className='auati accepted'>
                            <h3>Auati em negociação</h3>
                            <p>Converse com <b>{ad.person.fullname}</b> e alinhe os termos da troca de serviços.</p>
                            <button type='button' onClick={()=>this.openChat(auati)}>Continuar a negociação</button>
                        </div>
                        );
                    }
                    if (auati.status === "agreed") {
                        html = (
                        <div className='auati agreed'>
                            <h3>Serviços iniciados</h3>
                            <p>Os termos da troca foram acordados com <b>{ad.person.fullname}</b> e os serviços devem ser executados.</p>
                            <button type='submit' onClick={()=>this.openChat(auati)}>Comunicar término do serviço</button>
                            <button type='button' onClick={()=>this.openChat(auati)}>Mandar uma mensagem</button>
                        </div>
                        );
                    }
                    if (auati.status === "rating") {
                        html = (
                        <div className='auati agreed'>
                            <h3>Avalie os serviços</h3>
                            <p><b>{ad.person.fullname}</b> já comunicou o término dos serviços.</p>
                            <p>Avalie como foi sua experiência.</p>
                            <button type='submit' onClick={()=>this.openChat(auati)}>Avaliar agora</button>
                            <button type='button' onClick={()=>this.openChat(auati)}>Comunicar problema</button>
                        </div>
                        );
                    }
                    if (auati.status === "done") {
                        html = (
                        <div className='auati done'>
                            <h3>Auati concluído!</h3>
                            <p>Quer fazer outro Auati com <b>{ad.person.fullname}</b>?</p>
                            <button type='submit' onClick={this.requestAuati}>Fazer outro Auati</button>
                        </div>
                        );
                    }
                }
            } else {
                html = (<div className='auati requester'>
                    <button type='submit' onClick={this.requestAuati}>Quero fazer um Auati</button>
                </div>);
            }
        }


        return html;
    }
}

export default ButtonRequestAuati;