import React, { Component } from 'react'
import { Trans, withTranslation } from 'react-i18next';

export class firstMessage extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
             
        }
    }
    
    render() {
        //const { t } = this.props;
        return (
            <section className="first-message">
                <h1>
                    <span>
                    <Trans i18nKey="cover.title">
                    No Auati você <em>troca</em> o seu <em>trabalho</em> pelo que precisa. 
                    </Trans>
                    </span>
                    
                    <button type='submit'>
                        <Trans i18nKey="cover.cta">Anuncie grátis</Trans>
                    </button>
                </h1>
                <figure>
                    <img src="images/pages/illustration-cover.svg" alt="2 people happy to exchange their services" />
                </figure>
            </section>
        )
    }
}

export default withTranslation()(firstMessage);
