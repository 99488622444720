import React, { Component } from 'react';

/*
constructor(props) {
	super(props);
	
	this.chooseStatus = this.chooseStatus.bind(this);
	this.setFilter = this.setFilter.bind(this);
	this.searchField = this.searchField.bind(this);
	this.itemStatus = this.itemStatus.bind(this);
	this.logout = this.logout.bind(this);
	// this.doForget = this.doForget.bind(this);
	
	this.toggleMenu = this.toggleMenu.bind(this);
	this.hideMenu = this.hideMenu.bind(this);
	
	this.handleSubmitSearch = this.handleSubmitSearch.bind(this);
			
	this.handleSubmitSearchHistory = this.handleSubmitSearchHistory.bind(this);
	this.handleInputSearchHistory = this.handleInputSearchHistory.bind(this);
	
	this.state = {
		statuses: [],
		choosingStatus: false,
		showingMenu: true,
		userData: getUserData(),
		search: ''
	};
	
}

async componentDidMount() {
	if (isLogged()) {
		let result = await getStatuses();
		if (result && result.success) {
			this.setState({statuses: result.data });
		}
	}
}
*/
/*
logout() {
	this.props.doLogout();
	this.setState({
		showingMenu: false
	})
}


chooseStatus() {
	this.setState({ choosingStatus: !this.state.choosingStatus })
}

setFilter(status) {
	this.props.setFilterStatus(status);
	this.chooseStatus();
}

itemStatus(props) {
	const item = props.item;
	const action = props.action;
	const style = this.props.filterStatus.id === item.id ? "selected" : "";
	return (
		<li onClick={() => action(item)} className={style}>
			<img src={SERVER_URL + "/" + item.image} alt={item.text}/>
			<span>{item.text}</span>
		</li>
		);
	}
	

handleSubmitSearch(event) {
	event.preventDefault();
	this.props.setSearch(this.state.search);
}

handleSubmitSearchHistory(event) {
	event.preventDefault();
	this.props.setSearchHistory(this.state.searchHistory);
}
handleInputSearchHistory(event) {
	const value = event.target.value;
	//this.setState({ searchHistory: value });
	this.props.setSearchHistory(value);
}

searchField() {
	return (
		<form className="search" onSubmit={this.handleSubmitSearch}>
			<input 
			type="search" 
			required
			value={this.state.search}
			onKeyUp={this.handleInputSearch}
			onChange={this.handleInputSearch}
			placeholder="O que você está procurando?"
			/>
		</form>
	);
}

searchHistoryField() {
	return (
		<form className="search" onSubmit={this.handleSubmitSearchHistory}>
			<input 
			type="search" 
			required
			
			placeholder="Localizar atividade..."
			/>

		</form>
	);
}

toggleMenu() {
	this.setState({
		showingMenu: !this.state.showingMenu
	})
}
hideMenu(e){
	if(e && e.relatedTarget){
		e.relatedTarget.click();
	}
	this.setState({showingMenu: false});
}

*/

class SearchField extends Component {

	constructor(props) {
		super(props)
	
		this.state = {
			searchText: ''
		}

		this.handleInputSearch = this.handleInputSearch.bind(this);
		this.handleSubmitSearch = this.handleSubmitSearch.bind(this);

	}


	handleInputSearch(event) {
		const value = event.target.value;
		this.setState({ searchText: value });
		this.props.setSearch(this.state.searchText);
	}

	handleSubmitSearch(event) {
		event.preventDefault();
		this.props.setSearch(this.state.search);
	}
	

	render() {
		const wsize = document.body.clientWidth;
		const placeholder = wsize < 660 && wsize > 556 ? "Buscar por..." : "O que você está procurando?";
		
        return (
            <form className="search" onSubmit={this.handleSubmitSearch}>
				<input 
				type="search" 
				required
				value={this.state.searchText}
				onKeyUp={this.handleInputSearch}
				onChange={this.handleInputSearch}
				placeholder={placeholder}
				/>
			</form>
        )
    }
}

export default SearchField;
