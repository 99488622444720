import React from 'react';
import GlobalContext from '../globalContext.js';
import Ratings from './ratings.js';


class RatingButton extends React.Component {

    constructor(props) {
        super(props);
        this.openRatingDialog = this.openRatingDialog.bind(this);
    }

	static contextType = GlobalContext;

    openRatingDialog() {
        const ad = this.props.ad;
        this.context.setDialog({
            name: 'ratings',
            title: 'Classificações',
            modal: false,
            content: (<>
                <h2>{ad.title}</h2>
                <Ratings ad={ad} />
            </>)
        });
    }

    render() {
        const ad = this.props.ad;
        return (
            <button className="rating" onClick={this.openRatingDialog}>
                <span>{ad.rating}</span>
            </button>
        )
    }
}

export default RatingButton;